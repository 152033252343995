/* --------------------------
見出し
-------------------------- */

.c-h1 {
  font-size: 32px;
  font-weight: $semiBold;
  line-height: 1.4;
  letter-spacing: 0.05em;
  background: linear-gradient(
    135deg,
    rgb(65, 170, 211) 0%,
    rgb(152, 209, 231) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;

  @include view-at(sp) {
    font-size: rem(24px);
  }
}

.c-h2 {
  color: $color-blue;
  font-size: rem(28px);
  font-weight: $semiBold;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-align: center;
  padding-top: 20px;
  position: relative;

  @include view-at(sp) {
    font-size: rem(26px);
  }

  &::before {
    content: "";
    display: block;
    height: 6px;
    width: 40px;
    background: linear-gradient(
      90deg,
      rgb(65, 170, 211) 0%,
      rgb(152, 209, 231) 100%
    );
    border-radius: 8px 8px 0px 8px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &--small {
    color: #24292b;
    font-size: 12px;
    font-weight: $regular;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 64px;

    @include view-at(sp) {
      margin-bottom: 40px;
    }
  }
}

.c-h3 {
  font-size: rem(24px);
  font-weight: $medium;
  line-height: 1.4;
  color: $color-blue;
  letter-spacing: 0.05em;
  padding: 8px 0 8px 32px;
  position: relative;
  margin-bottom: 32px;

  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 6px;
    background: linear-gradient(
      180deg,
      rgb(65, 170, 211) 0%,
      rgb(152, 209, 231) 100%
    );
    border-radius: 8px 8px 0px 8px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.c-h4 {
  font-size: rem(20px);
  font-weight: $medium;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: $color-blue;
  margin-bottom: 16px;
}

.c-h5 {
  font-size: rem(16px);
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 8px;
}

/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  font-size: rem(16px);
  line-height: 1.6;
  border: 1px solid #98d1e7;

  tbody {
    tr {
      @include view-at(sp) {
        width: 100%;
      }
    }

    th {
      width: 300px;
      font-weight: $regular;
      padding: 16px 40px;
      background-color: #98d1e7;
      border: 1px solid;
      border-color: #98d1e7 $color-white $color-white #98d1e7;

      vertical-align: top;
      text-align: center;

      @include view-at(sp) {
        display: block;
        width: 100%;
        text-align: left;
        padding: 8px 16px;
      }
    }

    td {
      padding: 16px 40px;
      border: 1px solid #98d1e7;

      @include view-at(sp) {
        display: block;
        width: 100%;

        padding: 8px 16px;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  display: none;

  @include view-at(tab) {
    display: block;
    height: 64px;
    width: 64px;
    position: relative;
    border-radius: 0% 0% 0% 24%;
    background: linear-gradient(
      90deg,
      rgb(65, 170, 211) 0%,
      rgb(152, 209, 231) 100%
    );
  }

  &-trigger {
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
      color: #faf9f4;
      font-size: 24px;
      
    }

    &-label {
      color: #faf9f4;
      font-size: 12px;
    }
  }
}

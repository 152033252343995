.edit-post-visual-editor__post-title-wrapper,
.wp-block-post-content {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.edit-post-visual-editor__post-title-wrapper h1 {
  @extend .c-h1;
}

.entry-content,
.block-editor-block-list__layout,
.block-contents {
  h1 {
    @extend .c-h1;
  }
  h2 {
    @extend .c-h2;
    margin: 56px 0 !important;

    @include view-at(sp) {
      margin: 40px 0 !important;
    }
  }
  h3 {
    @extend .c-h3;
    margin: 40px 0 !important;

    @include view-at(sp) {
      margin: 32px 0 !important;
    }
  }

  h4 {
    @extend .c-panel-title;
    margin: 24px 0 !important;
  }

  h5 {
    @extend .c-h4;
  }

  ul {
    @extend .c-list;
    @extend .c-list--point;
  }

  ol {
    @extend .c-list;
    @extend .c-list--order;
  }

  a {
    text-decoration: underline;
  }

  p {
    font-weight: $regular;
    line-height: 1.6;
    color: $color-text;
    letter-spacing: 0.1em;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .wp-block-table {
    table {
      width: 100%;
      font-size: rem(16px);
      line-height: 1.6;
      border: 1px solid #98d1e7;

      thead {
        border-bottom: 3px solid $color-white;

        th {
          width: 300px;
          font-weight: $regular;
          padding: 16px 40px;
          background-color: #98d1e7;
          border: 1px solid;
          border-color: #98d1e7 $color-white $color-white #98d1e7;
          vertical-align: top;
          text-align: center;

          @include view-at(sp) {
            // display: block;
            // width: 100%;
            // text-align: left;
            padding: 8px 16px;
          }
        }
      }

      tbody {
        // tr {
        //   @include view-at(sp) {
        //     width: 100%;
        //   }
        // }

        td {
          padding: 16px 40px;
          border: 1px solid #98d1e7;

          @include view-at(sp) {
            // display: block;
            // width: 100%;
            padding: 8px 16px;

            // &:last-child {
            //   border-bottom: none;
            // }
          }
        }
      }
    }
  }
}

.wp-block {
  max-width: 100%;
}

.news {
  // &-h1 {
  //   background-image: url(../images/about/h1.jpg);
  // }

  &-section {
    &-container {
      max-width: 800px;
      padding: 0 20px;
      margin: 0 auto;
    }
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @include view-at(sp) {
      flex-wrap: wrap;
      gap: 16px;
    }

    &-item {
      a {
        display: inline-block;
        color: $color-black;
        font-size: 14px;
        font-weight: $medium;
        line-height: 1.4;
        padding-right: 2em;
        position: relative;

        &:hover {
          color: $color-blue;

          &::before {
            background: linear-gradient(
              225deg,
              #dfeff6 0%,
              rgb(152, 209, 231) 100%
            );
          }

          &::before,
          &::after {
            transform: scale(1.5, 1.5);
          }
        }

        &::before {
          content: "";
          display: block;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: linear-gradient(
            45deg,
            rgb(65, 170, 211) 0%,
            rgb(152, 209, 231) 100%
          );
          position: absolute;
          top: 0;
          right: 0;
          transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
        }

        &::after {
          content: "\e315";
          display: block;
          height: 20px;
          width: 20px;
          font-family: "Material Icons";
          font-size: rem(16px);
          color: $color-white;
          line-height: 20px;
          position: absolute;
          top: 0;
          right: 0;
          text-align: center;
          transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
        }
      }
    }
  }

  &-link {
    display: block;

    .p-image-thumbnail img {
      transition: all 0.5s;
    }

    &:hover {
      .p-image-thumbnail img {
        transform: scale(1.15);
      }
    }
  }

  &-label {
    display: inline-block;
    width: 8em;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-right: 16px;

    &.notice {
      background: linear-gradient(
        45deg,
        rgb(65, 170, 211) 0,
        rgb(152, 209, 231) 100%
      );
    }

    &.important {
      background: linear-gradient(45deg, #d34141 0, #e79898 100%);
    }

    &.daily {
      background: linear-gradient(45deg, #42d442 0, #99e899 100%);
    }

    &.news {
      background: linear-gradient(45deg, #d49742 0, #e8c799 100%);
    }

    &.recruit {
      background: linear-gradient(45deg, #427ed4 0, #99bae8 100%);
    }

    &.others {
      background: linear-gradient(45deg, #8c8c8c 0, #bfbfbf 100%);
    }
  }

  &-button {
    display: inline-block;
    color: $color-black;
    font-size: 14px;
    font-weight: $medium;
    line-height: 1.4;
    padding: 0 2em;
    position: relative;

    &:hover {
      color: $color-blue;

      &::before {
        background: linear-gradient(
          225deg,
          #dfeff6 0%,
          rgb(152, 209, 231) 100%
        );
      }

      &::before,
      &::after {
        transform: scale(1.5, 1.5);
      }
    }

    &--prev {
      &::before {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: linear-gradient(
          45deg,
          rgb(65, 170, 211) 0%,
          rgb(152, 209, 231) 100%
        );
        position: absolute;
        top: 0;
        right: 0;
        transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
      }

      &::after {
        content: "\e315";
        display: block;
        height: 20px;
        width: 20px;
        font-family: "Material Icons";
        font-size: rem(16px);
        color: $color-white;
        line-height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
      }
    }

    &--next {
      &::before {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: linear-gradient(
          45deg,
          rgb(65, 170, 211) 0%,
          rgb(152, 209, 231) 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
      }

      &::after {
        content: "\e314";
        display: block;
        height: 20px;
        width: 20px;
        font-family: "Material Icons";
        font-size: rem(16px);
        color: $color-white;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
      }
    }
  }
}

.c-group {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;

  @include view-at(sp) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &::before {
    content: "";
    display: block;
    width: calc(100% - 40px);
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;

    @include view-at(sp) {
      width: 100%;
    }
  }

  &--left {
    &::before {
      right: 0;
      border-top-left-radius: 100px;

      @include view-at(sp) {
        border-top-left-radius: 60px;
      }
    }
  }

  &--right {
    &::before {
      left: 0;
      border-top-right-radius: 100px;

      @include view-at(sp) {
        border-top-right-radius: 60px;
      }
    }
  }

  &--yellow {
    &::before {
      background: linear-gradient(180deg, #f4f2e9 0%, $color-white 100%);
    }
  }

  &--blue {
    &::before {
      background: linear-gradient(180deg, #ecf7fc 0%, $color-white 100%);
    }
  }
}

.p-cta {
  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    &--start {
      justify-content: flex-start;
    }

    @include view-at(sp) {
      flex-wrap: wrap;
      gap: 16px;
    }

    &-item {
      flex-shrink: 0;

      @include view-at(sp) {
        width: 100%;
      }
    }
  }
}

html {
  font-size: 16px;
}

body {
  font-feature-settings: "palt";
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  color: $color-black;
  font-size: rem(16px);
}

a {
  color: $color-black;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

p {
  font-weight: $regular;
  line-height: 1.6;
  color: $color-text;
  letter-spacing: 0.1em;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 72px;

  @include view-at(sp) {
    padding-top: 64px;
  }
}

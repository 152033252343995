.service {
  &-h1 {
    background-image: url(../images/service/h1.jpg);
    position: relative;
    z-index: -2;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(2px);
      background: inherit;
      z-index: -1;
    }
  }

  &-image {
    border-radius: 24px;
    overflow: hidden;

    @include view-at(sp) {
      border-radius: 16px;
    }
  }

  &-panel {
    background: #f8f6f0;
    border-radius: 24px;

    @include view-at(sp) {
      border-radius: 16px;
    }

    &--border {
      background: transparent;
      border: 2px dotted $color-blue;
    }

    &-contents {
      padding: 24px;

      @include view-at(sp) {
        padding: 16px;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -8px;

    &-item {
      width: 50%;
      color: $color-black;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 8px;

      @include view-at(sp) {
        width: 100%;
      }
    }
  }
}

.wp-pagenavi {
  text-align: center;

  .pages {
    display: none;
  }

  span,
  a {
    /*数字部分の共通CSS　大きさなど*/
    display: inline-block;
    margin: 0 8px;
    width: 3em;
    height: 3em;
    line-height: 3em;
    color: $color-white;
    text-decoration: none;
    background: #98d1e7;
    border-radius: 50%;
    border: none;

    &.current,
    &:hover {
      /*現在のページ*/
      color: $color-white;
      background: #41aad3;
    }

    &.nextpostslink,
    &.previouspostslink {
      display: none;
    }
  }
}

.p-accordion {
  &-list {
    &-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-wrapper {
    border-radius: 24px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    @include view-at(sp) {
      border-radius: 16px;
    }
  }

  &-header {
    padding: 24px;

    @include view-at(sp) {
      padding: 16px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;
      padding-right: 2em;

      &.active {
        &:after {
          transform: rotate(0);
        }
      }

      &::after {
        content: "\e316";
        font-family: "Material Icons";
        font-size: rem(28px);
        color: $color-blue;
        position: absolute;
        right: 0;
        transform: rotate(180deg);
        transition: all 0.3s;
      }
    }
  }

  &-icon {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(20px);
    line-height: 1;
    border-radius: 50%;

    &.question {
      background: linear-gradient(
        100deg,
        rgb(65, 170, 211) 0%,
        rgb(152, 209, 231) 100%
      );

      color: $color-white;
    }

    &.answer {
      color: $color-blue;
      background: $color-white;
      border: 2px solid $color-blue;
    }
  }

  &-question {
    font-weight: $medium;
    position: relative;
  }

  &-contents {
    display: none;
    padding: 24px;
    background: #ecf7fc;

    @include view-at(sp) {
      padding: 16px;
    }
  }

  &-inner {
    display: flex;

    gap: 16px;
  }
}

.p-grid {
  display: flex;
  gap: 24px;

  @include view-at(sp) {
    flex-wrap: wrap;
    gap: 16px;
  }

  &-headline {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;

    &-number {
      height: 56px;
      width: 56px;
      color: $color-blue;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.1em;
      line-height: 1.4;
      text-align: center;
      background: #ffffff;

      @include view-at(sp) {
        height: 48px;
        width: 48px;
        font-size: 20px;
      }
    }

    &-icon {
      height: 64px;
      width: 64px;
      background: linear-gradient(135deg, #ecf7fc 0%, $color-white 100%);

      @include view-at(sp) {
        height: 48px;
        width: 48px;
      }

      .icon {
        color: $color-blue;
        font-size: 36px;

        @include view-at(sp) {
          font-size: 20px;
        }
      }
    }
  }

  &-contents {
    width: 100%;
  }
}

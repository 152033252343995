/* --------------------------
リンク
-------------------------- */
.c-link {
  font-size: rem(16px);
  line-height: 1.4;
  font-weight: $medium;
  color: $color-black;
  padding-right: 3em;
  position: relative;

  &:hover {
    color: $color-blue;

    &::before,
    &::after {
      transform: scale(1.2, 1.2);
    }

    &::before {
      background: linear-gradient(225deg, #dfeff6 0%, rgb(152, 209, 231) 100%);
    }
  }

  &::before {
    content: "";
    display: block;
    height: 2em;
    width: 2em;
    background: linear-gradient(
      45deg,
      rgb(65, 170, 211) 0%,
      rgb(152, 209, 231) 100%
    );
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 1em);
    right: 0;
    transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
  }

  &::after {
    content: "\e315";
    height: 2em;
    width: 2em;
    font-family: "Material Icons";
    font-size: rem(16px);
    line-height: 2em;
    color: $color-white;
    position: absolute;
    top: calc(50% - 1em);
    right: 0;
    text-align: center;
    transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
  }
}

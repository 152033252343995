.p-mv {
  gap: 4px;
  height: 300px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(135deg, #dfeff6 0%, rgb(152, 209, 231) 52%);
  border-radius: 60px 0px 60px 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px 40px 24px 40px;

  @include view-at(sp) {
    height: 180px;
    margin: 0px 0 24px 0;
  }

  &-title {
    color: #ffffff;
    font-size: 32px;
    font-weight: $semiBold;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-align: center;

    @include view-at(sp) {
      font-size: rem(24px);
    }
  }

  &-text {
    color: #ffffff;
    font-size: 16px;
    font-weight: $regular;
    letter-spacing: 0.05em;
    line-height: 1.4;
    text-align: center;

    @include view-at(sp) {
      font-size: rem(14px);
    }
  }
}

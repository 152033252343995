.top {
  &-keyvisual {
    position: relative;
    height: 615px;
    margin: 40px 40px 80px 40px;
    border-radius: 70px 0px 70px 0px;
    overflow: hidden;
    max-width: calc(100% - 80px);

    @include view-at(sp) {
      height: 660px;
      border-radius: 60px 0px 60px 0px;
      margin: 8px 20px 40px 20px;
      max-width: calc(100% - 40px);
      background-position: left top;
    }

    &-slider {
      height: 100%;

      > .slick-list {
        height: 100%;

        > .slick-track {
          height: 100%;
        }
      }

      &-image {
        background-size: cover;
        background-position: top left;
        background-repeat: no-repeat;

        @include view-at(sp) {
          background-position: center center;
        }

        &01 {
          background-color: $color-gray;
        }

        &02 {
          background-color: $color-black;
        }
      }
    }

    &-contents {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 10px;
      padding: 48px;

      @include view-at(sp) {
        gap: 8px;
        padding: 16px 12px;
      }
    }

    &-text {
      color: $color-blue;
      font-size: 18px;
      line-height: 1.4;
      padding: 8px 16px;
      background: #ffffff;
      border-radius: 12px 12px 12px 0px;
      margin-bottom: 0;

      @include view-at(sp) {
        padding: 6px 10px;
      }
    }

    &-catchcopy {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0em;
      color: #ffffff;

      @include view-at(sp) {
        font-size: 6vw;
      }

      span {
        display: inline-block;
        margin-bottom: 10px;
        padding: 8px 16px;
        background: linear-gradient(
          107.74deg,
          rgb(65, 170, 211) 0%,
          rgb(152, 209, 231) 100%
        );
        border-radius: 16px 16px 16px 0px;

        &:last-child {
          margin-bottom: 0;
        }

        @include view-at(sp) {
          padding: 6px 10px;
        }
      }
    }
  }

  &-h2 {
    color: $color-blue;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.4;

    &-wrapper {
      padding-left: 16px;
      position: relative;
      margin-bottom: 24px;

      &::before {
        content: "";
        display: block;
        width: 6px;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgb(65, 170, 211) 0%,
          rgb(152, 209, 231) 100%
        );
        border-radius: 8px 8px 0px 8px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &-text {
      color: #24292b;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.4;
    }
  }

  &-news {
    &-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      a {
        display: block;

        &:hover {
          .top-news-title {
            color: $color-blue;
          }
        }
      }
    }

    &-date {
      font-size: 12px;
      font-weight: 500;
      color: #a5a5a5;
      margin-bottom: 6px;
    }

    &-title {
      color: #24292b;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 6px;
      transition: all 0.3s;
    }

    &-text {
      color: #24292b;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.6;
    }
  }

  &-schedule {
    &-table {
      width: 100%;
      border-collapse: collapse;
      color: #24292b;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;

      @include view-at(sp) {
        font-size: 12px;
      }

      tr {
        border-bottom: 1px solid rgba(51, 51, 51, 0.2);

        td {
          padding: 16px;
          text-align: center;

          @include view-at(sp) {
            padding: 6px;
          }
        }

        th {
          line-height: 1.5;
          padding: 16px 16px 16px 0;

          @include view-at(sp) {
            padding: 12px 12px 12px 0;
          }
        }
      }
    }
  }

  &-service {
    flex-wrap: nowrap;

    @include view-at(sp) {
      flex-wrap: wrap;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      width: 64px;
      background: linear-gradient(135deg, #ecf7fc 0%, $color-white 100%);
      border-radius: 50%;
      margin-right: 25px;
      flex-shrink: 0;

      @include view-at(sp) {
        margin-right: 0;
        margin-bottom: 16px;
        height: 48px;
        width: 48px;
      }

      i {
        color: $color-blue;
        font-size: 36px;

        @include view-at(sp) {
          font-size: 20px;
        }
      }
    }

    &-contents {
      flex-shrink: 1;
    }
  }

  &-image {
    margin-right: 40px;
    border-top-right-radius: 100px;
    overflow: hidden;
  }

  &-access {
    padding-top: 160px;

    @include view-at(sp) {
      padding-top: 100px;
    }

    &-logo {
      width: 320px;
      margin-bottom: 20px;

      @include view-at(sp) {
        width: 260px;
      }
    }

    &-map {
      position: relative;
      width: 100%;
      padding-top: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

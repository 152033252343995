/* --------------------------
フッター
-------------------------- */

.p-footer {
  margin-top: 120px;

  @include view-at(sp) {
    margin-top: 70px;
  }

  &-cta {
    padding: 100px 0;
    background: linear-gradient(
      135deg,
      rgb(65, 170, 211) 0%,
      rgb(152, 209, 231) 100%
    );
    border-radius: 100px 100px 0px 0px;

    @include view-at(sp) {
      padding: 70px 0;
      border-radius: 60px 60px 0px 0px;
    }

    &-title {
      @extend .c-h2;
      color: $color-white;

      &::before {
        @extend .c-h2::before;
        background: linear-gradient(90deg, #dfeff6 0%, $color-white 100%);
      }
    }

    &--small {
      @extend .c-h2--small;
      color: $color-white;
    }
  }

  &-contents {
    padding: 32px 40px 20px 40px;

    @include view-at(sp) {
      padding: 32px 20px 20px 20px;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 24px;

    @include view-at(sp) {
      flex-wrap: wrap;
    }
  }

  &-logo {
    a {
      display: block;

      img {
        width: 260px;
      }
    }
  }

  &-sns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    &-item {
      a {
        font-size: rem(24px);
        color: #24292b;
        padding: 1px;

        &:hover {
          color: rgb(65, 170, 211);
        }
      }
    }
  }

  &-nav {
    margin-bottom: 40px;

    // @include view-at(tab) {
    //   display: none;
    // }

    &-list {
      display: flex;
      align-items: center;
      margin: -4px;

      @include view-at(tab) {
        flex-wrap: wrap;
      }

      &-item {
        padding: 4px;

        @include view-at(tab) {
          &:not(:last-child) {
            display: none;
          }
        }

        a {
          display: block;
          font-weight: $medium;
          padding: 6px 8px;
          border-radius: 8px 8px 8px 0px;

          &:hover {
            color: $color-white;
            background: linear-gradient(
              107.74deg,
              rgb(65, 170, 211) 0%,
              rgb(152, 209, 231) 100%
            );
          }

          @include view-at(tab) {
            font-size: rem(14px);
            padding: 8px;
          }
        }
      }
    }
  }

  &-copyright {
    color: $color-black;
    font-size: 12px;
    font-weight: 400;
  }
}

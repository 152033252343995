/* --------------------------
color
-------------------------- */

$color-white: #fff;
$color-black: #333;
$color-text: #24292b;
$color-blue: #41aad3;

$color-light-gray: #f2f2f2;
$color-mid-gray: #bfbfbf;
$color-gray: #a2a2a2;
$color-dark-gray: #808080;

/* --------------------------
spacing
-------------------------- */
$space: 80px, 40px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 40px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$thin: 100;
$extraLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;
$black: 900;
/* --------------------------
contents width
-------------------------- */
$contents-width: 1240px;
$contents-narrow-width: 1000px;
$contents-padding: 20px;
$contents-padding-sp: 20px;

/* --------------------------
z-index order
-------------------------- */
$z: (contents, arrow, gnav, leftbar, header, footer, modal);

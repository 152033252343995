/* --------------------------
リスト
-------------------------- */
.c-list {
  font-size: rem(16px);
  margin-bottom: 2em;

  > li {
    line-height: 1.6;
    margin-bottom: 0.5em;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--point {
    > li {
      padding-left: 1em;

      &::before {
        content: "";
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        display: inline-block;
        background-color: #41aad3;
        position: absolute;
        top: 0.6em;
        left: 0;
      }
    }
  }

  &--order {
    padding-left: 1.5em;
  }

  &-anchor {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @include view-at(sp) {
      flex-wrap: wrap;
      gap: 16px;
    }

    &-item {
      a {
        display: inline-block;
        color: $color-black;
        font-size: 14px;
        font-weight: $medium;
        line-height: 1.4;
        padding-right: 2em;
        position: relative;

        &:hover {
          color: $color-blue;

          &::before {
            background: linear-gradient(
              225deg,
              #dfeff6 0%,
              rgb(152, 209, 231) 100%
            );
          }

          &::before,
          &::after {
            transform: scale(1.5, 1.5);
          }
        }

        &::before {
          content: "";
          display: block;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: linear-gradient(
            45deg,
            rgb(65, 170, 211) 0%,
            rgb(152, 209, 231) 100%
          );
          position: absolute;
          top: 0;
          right: 0;
          transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
        }

        &::after {
          content: "\e313";
          display: block;
          height: 20px;
          width: 20px;
          font-family: "Material Icons";
          font-size: rem(16px);
          color: $color-white;
          line-height: 20px;
          position: absolute;
          top: 0;
          right: 0;
          text-align: center;
          transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
        }
      }
    }
  }
}

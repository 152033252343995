/* --------------------------
パネル
-------------------------- */

.c-panel {
  background: $color-white;
  border-radius: 40px 0px 40px 0px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);

  &--blue {
    background: linear-gradient(180deg, #ecf7fc 0%, $color-white 100%);
  }

  &-thumbnail {
    border-radius: 40px 0px 0px 0px;
    overflow: hidden;
  }

  &-contents {
    padding: 24px;

    &--narrow {
      padding: 56px;

      @include view-at(sp) {
        padding: 40px;
      }
    }
  }

  &-title {
    color: $color-blue;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.05em;
    padding-bottom: 16px;
    margin-bottom: 16px;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background: linear-gradient(135deg, #dfeff6 0%, rgb(152, 209, 231) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.contact {
  &-list {
    &-item {
      margin-bottom: 2em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 1em;
  }

  &-link {
    padding-right: 0.5em;
    color: $color-blue;
    text-decoration: underline;
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 64px;
    width: 50%;
    margin: 40px auto 0;
    font-weight: $medium;
    line-height: 1.4;
    border-radius: 40px 40px 40px 0px;
    border: 2px solid $color-white;
    background: linear-gradient(
      100deg,
      rgb(65, 170, 211) 0%,
      rgb(152, 209, 231) 100%
    );
    font-size: rem(20px);
    letter-spacing: 0.05em;
    color: $color-white;
    text-align: center;

    @include view-at(sp) {
      width: 100%;
    }

    &:hover {
      color: $color-blue;
      background: linear-gradient(225deg, #dfeff6 0%, rgb(152, 209, 231) 100%);
    }
  }
}

input[type="radio"],
input[type="checkbox"] {
  appearance: none;
}

.wpcf7-form-control.wpcf7-radio {
  display: flex;
  gap: 2em;

  @include view-at(sp) {
    flex-wrap: wrap;
  }
}

.wpcf7-list-item {
  margin: 0 !important;
}

.wpcf7-list-item input[type="radio"]:checked + .wpcf7-list-item-label::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 8px solid $color-blue;
  border-radius: 50%;
}

input[type="radio"] + .wpcf7-list-item-label {
  @extend .c-form-radio-span;
}

input[type="radio"] + .wpcf7-list-item-label::before {
  @extend .c-form-radio-span::before;
}

input[type="checkbox"] + .wpcf7-list-item-label {
  @extend .c-form-checkbox-span;
}

input[type="checkbox"] + .wpcf7-list-item-label::before {
  @extend .c-form-checkbox-span::before;
}

.wpcf7-list-item
  input[type="checkbox"]:checked
  + .wpcf7-list-item-label::before {
  background: $color-blue;
}

.wpcf7-list-item
  input[type="checkbox"]:checked
  + .wpcf7-list-item-label::after {
  content: "";
  display: block;
  width: 0.5em;
  height: 1em;
  position: absolute;
  top: 0.2em;
  left: 0.5em;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.wpcf7 form .wpcf7-response-output {
  padding: 1em;
  text-align: center;
}

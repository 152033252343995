/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;

  @include view-at(tab) {
    position: absolute;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 50%;
    height: 100vh;
    background: $color-white;
    padding-top: 64px;
    display: block;
    transition: all 0.6s;

    &.active {
      right: 0;
    }

    &-overlay {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      transition: all 0.6s;
      visibility: hidden;

      &.visible {
        background: rgba(0, 0, 0, 0.15);
        visibility: visible;
      }
    }
  }

  @include view-at(sp){
    width: 280px;
  }

  &-list {
    display: flex;
    gap: 8px;

    @include view-at(tab) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 32px 24px 16px 24px;
    }

    &-item {
      a {
        display: inline-block;
        font-size: 14px;
        font-weight: $medium;
        color: #24292b;
        padding: 6px 8px 6px 8px;
        border-radius: 8px 8px 8px 0px;

        &:hover {
          color: $color-white;
          background: linear-gradient(
            107.74deg,
            rgb(65, 170, 211) 0%,
            rgb(152, 209, 231) 100%
          );
        }
      }
    }
  }
}

/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  height: 72px;
  width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background: $color-white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding-left: 40px;
  z-index: 100;

  @include view-at(tab) {
    height: 64px;
    padding-left: 20px;
  }

  &-logo {
    a {
      display: block;

      img {
        width: 260px;

        @include view-at(tab) {
          width: 216px;
        }
      }
    }
  }
}

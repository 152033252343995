/* --------------------------
背景色
-------------------------- */

.u-bg-light {
  background-color: $color-gray;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-black {
  background-color: $color-black;
}

.u-bg-slope {
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    /*四角形を傾けます*/
    transform: skewY(5deg);
    transform-origin: top right;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #ddd;
  }
}

/* --------------------------
テキスト装飾
-------------------------- */
.u-text-inverse {
  color: $color-white;
}

.u-text-important {
  color: #d34141;
}

.u-text-accent {
  color: $color-blue;
}

.u-text-thin {
  font-weight: $thin;
}
.u-text-extraLight {
  font-weight: $extraLight;
}
.u-text-light {
  font-weight: $light;
}
.u-text-regular {
  font-weight: $regular;
}
.u-text-medium {
  font-weight: $medium;
}
.u-text-semiBold {
  font-weight: $semiBold;
}
.u-text-bold {
  font-weight: $bold;
}
.u-text-extraBold {
  font-weight: $extraBold;
}
.u-text-black {
  font-weight: $black;
}

.u-text-x-small {
  font-size: rem(12px);
}

.u-text-small {
  font-size: rem(14px);
}

.u-text-default {
  font-size: rem(16px);
}

.u-text-large {
  font-size: rem(18px);
}

.u-text-x-large {
  font-size: rem(20px);
}

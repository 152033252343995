.p-cvnav {
  @include view-at(tab) {
    padding: 16px 24px 24px 24px;
  }

  &-list {
    display: flex;

    @include view-at(tab) {
      flex-wrap: wrap;
      gap: 20px;
    }

    &-item {
      height: 72px;
      width: 160px;

      @include view-at(tab) {
        height: 64px;
        width: 100%;
      }
    }
  }

  &-button {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $medium;
    font-size: rem(16px);
    line-height: 1.4;

    @include view-at(sp) {
      font-size: rem(20px);
    }

    &::before {
      font-family: "Material Icons";
      font-size: rem(16px);
      margin-right: 8px;

      @include view-at(sp) {
        font-size: rem(20px);
      }
    }

    &.contact {
      background: linear-gradient(
        135deg,
        rgb(65, 170, 211) 0%,
        rgb(152, 209, 231) 100%
      );
      border-radius: 0px 0px 0px 24px;
      color: $color-white;

      @include view-at(tab) {
        border-radius: 40px 40px 40px 0px;
      }

      &::before {
        content: "\e0e1";
      }

      &:hover {
        color: $color-blue;
        background: linear-gradient(
          225deg,
          #dfeff6 0%,
          rgb(152, 209, 231) 100%
        );
      }
    }

    &.tel {
      color: $color-blue;
      background: linear-gradient(135deg, #dfeff6 0%, $color-white 100%);

      &::before {
        content: "\e324";
      }

      &:hover {
        color: $color-white;
        background: linear-gradient(
          225deg,
          #dfeff6 0%,
          rgb(152, 209, 231) 100%
        );
      }

      @include view-at(tab) {
        border-radius: 40px 40px 40px 0px;
      }
    }
  }
}

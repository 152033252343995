@charset "UTF-8";
/* --------------------------
color
-------------------------- */
/* --------------------------
spacing
-------------------------- */
/* --------------------------
font
-------------------------- */
/* --------------------------
contents width
-------------------------- */
/* --------------------------
z-index order
-------------------------- */
/* --------------------------
media query
-------------------------- */
/* --------------------------
h with center-line
-------------------------- */
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?nxc2oh");
  src: url("../fonts/icomoon.eot?nxc2oh#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?nxc2oh") format("truetype"), url("../fonts/icomoon.woff?nxc2oh") format("woff"), url("../fonts/icomoon.svg?nxc2oh#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e902";
}

.icon-youtube:before {
  content: "\e912";
}

.icon-facebook:before {
  content: "\ea90";
}

.icon-twitter:before {
  content: "\ea96";
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 300;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

html {
  font-size: 16px;
}

body {
  font-feature-settings: "palt";
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  color: #333;
  font-size: 1rem;
}

a {
  color: #333;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

p {
  font-weight: 400;
  line-height: 1.6;
  color: #24292b;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
}

p:last-child {
  margin-bottom: 0;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 72px;
}

@media screen and (max-width: 767px) {
  .main {
    padding-top: 64px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.edit-post-visual-editor__post-title-wrapper,
.wp-block-post-content {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.entry-content h2,
.block-editor-block-list__layout h2,
.block-contents h2 {
  margin: 56px 0 !important;
}

@media screen and (max-width: 767px) {
  .entry-content h2,
  .block-editor-block-list__layout h2,
  .block-contents h2 {
    margin: 40px 0 !important;
  }
}

.entry-content h3,
.block-editor-block-list__layout h3,
.block-contents h3 {
  margin: 40px 0 !important;
}

@media screen and (max-width: 767px) {
  .entry-content h3,
  .block-editor-block-list__layout h3,
  .block-contents h3 {
    margin: 32px 0 !important;
  }
}

.entry-content h4,
.block-editor-block-list__layout h4,
.block-contents h4 {
  margin: 24px 0 !important;
}

.entry-content a,
.block-editor-block-list__layout a,
.block-contents a {
  text-decoration: underline;
}

.entry-content p,
.block-editor-block-list__layout p,
.block-contents p {
  font-weight: 400;
  line-height: 1.6;
  color: #24292b;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
}

.entry-content p:last-child,
.block-editor-block-list__layout p:last-child,
.block-contents p:last-child {
  margin-bottom: 0;
}

.entry-content .wp-block-table table,
.block-editor-block-list__layout .wp-block-table table,
.block-contents .wp-block-table table {
  width: 100%;
  font-size: 1rem;
  line-height: 1.6;
  border: 1px solid #98d1e7;
}

.entry-content .wp-block-table table thead,
.block-editor-block-list__layout .wp-block-table table thead,
.block-contents .wp-block-table table thead {
  border-bottom: 3px solid #fff;
}

.entry-content .wp-block-table table thead th,
.block-editor-block-list__layout .wp-block-table table thead th,
.block-contents .wp-block-table table thead th {
  width: 300px;
  font-weight: 400;
  padding: 16px 40px;
  background-color: #98d1e7;
  border: 1px solid;
  border-color: #98d1e7 #fff #fff #98d1e7;
  vertical-align: top;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .entry-content .wp-block-table table thead th,
  .block-editor-block-list__layout .wp-block-table table thead th,
  .block-contents .wp-block-table table thead th {
    padding: 8px 16px;
  }
}

.entry-content .wp-block-table table tbody td,
.block-editor-block-list__layout .wp-block-table table tbody td,
.block-contents .wp-block-table table tbody td {
  padding: 16px 40px;
  border: 1px solid #98d1e7;
}

@media screen and (max-width: 767px) {
  .entry-content .wp-block-table table tbody td,
  .block-editor-block-list__layout .wp-block-table table tbody td,
  .block-contents .wp-block-table table tbody td {
    padding: 8px 16px;
  }
}

.wp-block {
  max-width: 100%;
}

.wp-pagenavi {
  text-align: center;
}

.wp-pagenavi .pages {
  display: none;
}

.wp-pagenavi span,
.wp-pagenavi a {
  /*数字部分の共通CSS　大きさなど*/
  display: inline-block;
  margin: 0 8px;
  width: 3em;
  height: 3em;
  line-height: 3em;
  color: #fff;
  text-decoration: none;
  background: #98d1e7;
  border-radius: 50%;
  border: none;
}

.wp-pagenavi span.current, .wp-pagenavi span:hover,
.wp-pagenavi a.current,
.wp-pagenavi a:hover {
  /*現在のページ*/
  color: #fff;
  background: #41aad3;
}

.wp-pagenavi span.nextpostslink, .wp-pagenavi span.previouspostslink,
.wp-pagenavi a.nextpostslink,
.wp-pagenavi a.previouspostslink {
  display: none;
}

/* --------------------------
パンくずナビ
-------------------------- */
.c-breadcrumb {
  height: 18px;
  margin: 16px 0;
  background: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 767px) {
  .c-breadcrumb {
    height: auto;
    margin: 12px 0;
  }
}

.c-breadcrumb-container {
  padding: 0 40px;
}

@media screen and (max-width: 767px) {
  .c-breadcrumb-container {
    padding: 0 20px;
  }
}

.c-breadcrumb-list {
  display: flex;
  align-items: flex-start;
}

.c-breadcrumb-list li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-breadcrumb-list li::after {
  content: "＞";
  margin: 0 12px;
  color: #a5a5a5;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
}

.c-breadcrumb-list li:last-child::after {
  content: none;
}

.c-breadcrumb-list li a {
  color: #a5a5a5;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
}

.c-breadcrumb-list li span {
  color: #41aad3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.4;
}

/* --------------------------
ボタン
-------------------------- */
.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 64px;
  width: 240px;
  font-weight: 500;
  line-height: 1.4;
  border-radius: 40px 40px 40px 0px;
}

@media screen and (max-width: 767px) {
  .c-button {
    width: 100%;
  }
}

.c-button::before {
  font-family: "Material Icons";
  font-size: 1.25rem;
  margin-right: 16px;
}

.c-button--blue {
  background: linear-gradient(100deg, #41aad3 0%, #98d1e7 100%);
  border: none;
}

.c-button--border {
  background: linear-gradient(135deg, #ecf7fc 0%, #fff 100%);
  border: 2px solid #41aad3;
}

.c-button--contact {
  font-size: 1.25rem;
  color: #fff;
  text-align: center;
  border: 2px solid #fff;
}

.c-button--contact:hover {
  color: #41aad3;
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

.c-button--contact::before {
  content: "\e0e1";
}

.c-button--tel {
  font-size: 12px;
  background: linear-gradient(45deg, #ecf7fc 0%, #fff 100%);
  color: #41aad3;
}

.c-button--tel:hover {
  color: #fff;
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

.c-button--tel::before {
  content: "\e324";
}

/* --------------------------
フォーム
-------------------------- */
.c-form-text {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  padding: 8px 12px;
  border: 1px solid #a2a2a2;
  border-radius: 4px;
}

.c-form-textarea {
  width: 100%;
  height: 10em;
  font-size: 1rem;
  line-height: 1.5;
  padding: 8px 12px;
  border: 1px solid #a2a2a2;
  border-radius: 4px;
}

.c-form-label {
  display: flex;
  align-items: center;
}

.c-form-checkbox-input {
  appearance: none;
}

.c-form-checkbox-input:checked + .c-form-checkbox-span::before, input.c-form-checkbox-input[type="checkbox"]:checked + .wpcf7-list-item-label::before {
  background: #41aad3;
}

.c-form-checkbox-input:checked + .c-form-checkbox-span::after, input.c-form-checkbox-input[type="checkbox"]:checked + .wpcf7-list-item-label::after {
  content: "";
  display: block;
  width: 0.5em;
  height: 1em;
  position: absolute;
  top: 0.2em;
  left: 0.5em;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.c-form-checkbox-span, input[type="checkbox"] + .wpcf7-list-item-label {
  padding-left: 2em;
  position: relative;
  cursor: pointer;
}

.c-form-checkbox-span::before, input[type="checkbox"] + .wpcf7-list-item-label::before {
  content: "";
  display: block;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #a2a2a2;
}

.c-form-radio-input {
  appearance: none;
}

.c-form-radio-input:checked + .c-form-radio-span::after, input.c-form-radio-input[type="radio"]:checked + .wpcf7-list-item-label::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 8px solid #41aad3;
  border-radius: 50%;
}

.c-form-radio-span, input[type="radio"] + .wpcf7-list-item-label {
  padding-left: 2em;
  position: relative;
  cursor: pointer;
}

.c-form-radio-span::before, input[type="radio"] + .wpcf7-list-item-label::before {
  content: "";
  display: block;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #a2a2a2;
  border-radius: 50%;
}

.c-form-select {
  display: block;
  padding: 6px 5px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  appearance: none;
  border: 0;
  border: 1px solid #a2a2a2;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' class='svg-inline--fa fa-angle-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.c-form-select::-ms-expand {
  display: none;
}

.c-form-select:hover {
  border-color: #888;
}

.c-form-select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.c-form-select--small {
  width: 50%;
  display: inline-block;
}

.c-form-select option {
  font-weight: normal;
}

/* --------------------------
見出し
-------------------------- */
.c-h1, .edit-post-visual-editor__post-title-wrapper h1, .entry-content h1,
.block-editor-block-list__layout h1,
.block-contents h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.05em;
  background: linear-gradient(135deg, #41aad3 0%, #98d1e7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .c-h1, .edit-post-visual-editor__post-title-wrapper h1, .entry-content h1,
  .block-editor-block-list__layout h1,
  .block-contents h1 {
    font-size: 1.5rem;
  }
}

.c-h2, .entry-content h2,
.block-editor-block-list__layout h2,
.block-contents h2, .p-footer-cta-title {
  color: #41aad3;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-align: center;
  padding-top: 20px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-h2, .entry-content h2,
  .block-editor-block-list__layout h2,
  .block-contents h2, .p-footer-cta-title {
    font-size: 1.625rem;
  }
}

.c-h2::before, .entry-content h2::before,
.block-editor-block-list__layout h2::before,
.block-contents h2::before, .p-footer-cta-title::before {
  content: "";
  display: block;
  height: 6px;
  width: 40px;
  background: linear-gradient(90deg, #41aad3 0%, #98d1e7 100%);
  border-radius: 8px 8px 0px 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.c-h2--small, .p-footer-cta--small {
  color: #24292b;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 64px;
}

@media screen and (max-width: 767px) {
  .c-h2--small, .p-footer-cta--small {
    margin-bottom: 40px;
  }
}

.c-h3, .entry-content h3,
.block-editor-block-list__layout h3,
.block-contents h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
  color: #41aad3;
  letter-spacing: 0.05em;
  padding: 8px 0 8px 32px;
  position: relative;
  margin-bottom: 32px;
}

.c-h3::before, .entry-content h3::before,
.block-editor-block-list__layout h3::before,
.block-contents h3::before {
  content: "";
  display: block;
  height: 100%;
  width: 6px;
  background: linear-gradient(180deg, #41aad3 0%, #98d1e7 100%);
  border-radius: 8px 8px 0px 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.c-h4, .entry-content h5,
.block-editor-block-list__layout h5,
.block-contents h5 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #41aad3;
  margin-bottom: 16px;
}

.c-h5 {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.c-h--center-line {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.c-h--center-line::before, .c-h--center-line::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: #4f574d;
  display: block;
}

.c-h--center-line::before {
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .c-h--center-line::before {
    margin-right: 15px;
  }
}

.c-h--center-line::after {
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  .c-h--center-line::after {
    margin-left: 15px;
  }
}

/* --------------------------
ラベル
-------------------------- */
.c-label {
  display: inline-block;
  padding: 5px 15px;
  line-height: 1;
  background-color: #333;
  color: #fff;
  font-size: 0.875rem;
  letter-spacing: 1px;
}

/* --------------------------
線
-------------------------- */
.c-line--dashed {
  border-top: 2px dashed #7D6A00;
}

/* --------------------------
リンク
-------------------------- */
.c-link {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 500;
  color: #333;
  padding-right: 3em;
  position: relative;
}

.c-link:hover {
  color: #41aad3;
}

.c-link:hover::before, .c-link:hover::after {
  transform: scale(1.2, 1.2);
}

.c-link:hover::before {
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

.c-link::before {
  content: "";
  display: block;
  height: 2em;
  width: 2em;
  background: linear-gradient(45deg, #41aad3 0%, #98d1e7 100%);
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 1em);
  right: 0;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

.c-link::after {
  content: "\e315";
  height: 2em;
  width: 2em;
  font-family: "Material Icons";
  font-size: 1rem;
  line-height: 2em;
  color: #fff;
  position: absolute;
  top: calc(50% - 1em);
  right: 0;
  text-align: center;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

/* --------------------------
リスト
-------------------------- */
.c-list, .entry-content ul,
.block-editor-block-list__layout ul,
.block-contents ul, .entry-content ol,
.block-editor-block-list__layout ol,
.block-contents ol {
  font-size: 1rem;
  margin-bottom: 2em;
}

.c-list > li, .entry-content ul > li, .block-editor-block-list__layout ul > li, .block-contents ul > li, .entry-content ol > li, .block-editor-block-list__layout ol > li, .block-contents ol > li {
  line-height: 1.6;
  margin-bottom: 0.5em;
  position: relative;
}

.c-list > li:last-child, .entry-content ul > li:last-child, .block-editor-block-list__layout ul > li:last-child, .block-contents ul > li:last-child, .entry-content ol > li:last-child, .block-editor-block-list__layout ol > li:last-child, .block-contents ol > li:last-child {
  margin-bottom: 0;
}

.c-list--point > li, .entry-content ul > li, .block-editor-block-list__layout ul > li, .block-contents ul > li {
  padding-left: 1em;
}

.c-list--point > li::before, .entry-content ul > li::before, .block-editor-block-list__layout ul > li::before, .block-contents ul > li::before {
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  display: inline-block;
  background-color: #41aad3;
  position: absolute;
  top: 0.6em;
  left: 0;
}

.c-list--order, .entry-content ol,
.block-editor-block-list__layout ol,
.block-contents ol {
  padding-left: 1.5em;
}

.c-list-anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

@media screen and (max-width: 767px) {
  .c-list-anchor {
    flex-wrap: wrap;
    gap: 16px;
  }
}

.c-list-anchor-item a {
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  padding-right: 2em;
  position: relative;
}

.c-list-anchor-item a:hover {
  color: #41aad3;
}

.c-list-anchor-item a:hover::before {
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

.c-list-anchor-item a:hover::before, .c-list-anchor-item a:hover::after {
  transform: scale(1.5, 1.5);
}

.c-list-anchor-item a::before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: linear-gradient(45deg, #41aad3 0%, #98d1e7 100%);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

.c-list-anchor-item a::after {
  content: "\e313";
  display: block;
  height: 20px;
  width: 20px;
  font-family: "Material Icons";
  font-size: 1rem;
  color: #fff;
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */
.c-menu {
  display: none;
}

@media screen and (max-width: 1024px) {
  .c-menu {
    display: block;
    height: 64px;
    width: 64px;
    position: relative;
    border-radius: 0% 0% 0% 24%;
    background: linear-gradient(90deg, #41aad3 0%, #98d1e7 100%);
  }
}

.c-menu-trigger {
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.c-menu-trigger .icon {
  color: #faf9f4;
  font-size: 24px;
}

.c-menu-trigger-label {
  color: #faf9f4;
  font-size: 12px;
}

/* --------------------------
パネル
-------------------------- */
.c-panel {
  background: #fff;
  border-radius: 40px 0px 40px 0px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
}

.c-panel--blue {
  background: linear-gradient(180deg, #ecf7fc 0%, #fff 100%);
}

.c-panel-thumbnail {
  border-radius: 40px 0px 0px 0px;
  overflow: hidden;
}

.c-panel-contents {
  padding: 24px;
}

.c-panel-contents--narrow {
  padding: 56px;
}

@media screen and (max-width: 767px) {
  .c-panel-contents--narrow {
    padding: 40px;
  }
}

.c-panel-title, .entry-content h4,
.block-editor-block-list__layout h4,
.block-contents h4 {
  color: #41aad3;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.05em;
  padding-bottom: 16px;
  margin-bottom: 16px;
  position: relative;
}

.c-panel-title::after, .entry-content h4::after,
.block-editor-block-list__layout h4::after,
.block-contents h4::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background: linear-gradient(135deg, #dfeff6 0%, #98d1e7 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}

/* --------------------------
セクション
-------------------------- */
@media screen and (min-width: 768px) {
  .c-section {
    margin: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section {
    margin: 40px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--middle {
    margin: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--middle {
    margin: 30px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--narrow {
    margin: 30px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--narrow {
    margin: 20px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg {
    padding: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg {
    padding: 40px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg--middle {
    padding: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg--middle {
    padding: 30px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg--narrow {
    padding: 30px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg--narrow {
    padding: 20px 0;
  }
}

.c-section-container {
  max-width: 1240px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .c-section-container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1024px) {
  .c-section-container {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-section-container {
    max-width: 100%;
    padding: 0 20px;
  }
}

.c-section-container--middle {
  max-width: 900px;
  margin: auto;
  padding: 0 20px;
}

.c-section-container--narrow {
  max-width: 1000px;
  margin: auto;
  padding: 0 20px;
}

@media screen and (max-width: 1024px) {
  .c-section-container--narrow {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-section-container--fill-sp {
    padding: 0;
    overflow: hidden;
  }
}

/* --------------------------
タブ
-------------------------- */
.c-tab-nav {
  display: flex;
}

@media screen and (max-width: 767px) {
  .c-tab-nav {
    flex-wrap: wrap;
    margin: -4px;
  }
}

.c-tab-nav-item {
  flex-grow: 1;
}

@media screen and (max-width: 767px) {
  .c-tab-nav-item {
    width: 50%;
    padding: 4px;
  }
}

.c-tab-nav-item:last-child a {
  border-right: none;
}

.c-tab-nav-item a {
  display: block;
  color: #333;
  text-align: center;
  padding: 20px;
  background-color: #f2f2f2;
  border-right: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  .c-tab-nav-item a {
    padding: 15px 5px;
    font-size: 13px;
  }
}

.c-tab-nav-item a[aria-selected="true"] {
  background-color: #bfbfbf;
  color: #fff;
}

.c-tab-contents {
  padding: 20px;
  background-color: #bfbfbf;
}

@media screen and (max-width: 767px) {
  .c-tab-contents {
    margin-top: 8px;
  }
}

.c-tab-contents-item {
  display: none;
}

.c-tab-contents-item[aria-hidden="false"] {
  display: block;
}

/* --------------------------
テーブル
-------------------------- */
.c-table {
  width: 100%;
  font-size: 1rem;
  line-height: 1.6;
  border: 1px solid #98d1e7;
}

@media screen and (max-width: 767px) {
  .c-table tbody tr {
    width: 100%;
  }
}

.c-table tbody th {
  width: 300px;
  font-weight: 400;
  padding: 16px 40px;
  background-color: #98d1e7;
  border: 1px solid;
  border-color: #98d1e7 #fff #fff #98d1e7;
  vertical-align: top;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-table tbody th {
    display: block;
    width: 100%;
    text-align: left;
    padding: 8px 16px;
  }
}

.c-table tbody td {
  padding: 16px 40px;
  border: 1px solid #98d1e7;
}

@media screen and (max-width: 767px) {
  .c-table tbody td {
    display: block;
    width: 100%;
    padding: 8px 16px;
  }
  .c-table tbody td:last-child {
    border-bottom: none;
  }
}

.c-group {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-group {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.c-group::before {
  content: "";
  display: block;
  width: calc(100% - 40px);
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .c-group::before {
    width: 100%;
  }
}

.c-group--left::before {
  right: 0;
  border-top-left-radius: 100px;
}

@media screen and (max-width: 767px) {
  .c-group--left::before {
    border-top-left-radius: 60px;
  }
}

.c-group--right::before {
  left: 0;
  border-top-right-radius: 100px;
}

@media screen and (max-width: 767px) {
  .c-group--right::before {
    border-top-right-radius: 60px;
  }
}

.c-group--yellow::before {
  background: linear-gradient(180deg, #f4f2e9 0%, #fff 100%);
}

.c-group--blue::before {
  background: linear-gradient(180deg, #ecf7fc 0%, #fff 100%);
}

/* --------------------------
テキスト整列
-------------------------- */
.u-align-left {
  text-align: left;
}

.u-align-right {
  text-align: right;
}

.u-align-center {
  text-align: center;
}

.u-align-justify {
  text-align: justify;
  word-wrap: break-word;
}

@media screen and (min-width: 768px) {
  .u-align-left--pc {
    text-align: left;
  }
  .u-align-right--pc {
    text-align: right;
  }
  .u-align-center--pc {
    text-align: center;
  }
}

@media screen and (max-width: 1180px) {
  .u-align-left--laptop {
    text-align: left;
  }
  .u-align-right--laptop {
    text-align: right;
  }
  .u-align-center--laptop {
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .u-align-left--tab {
    text-align: left;
  }
  .u-align-right--tab {
    text-align: right;
  }
  .u-align-center--tab {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .u-align-left--sp {
    text-align: left;
  }
  .u-align-right--sp {
    text-align: right;
  }
  .u-align-center--sp {
    text-align: center;
  }
}

/* --------------------------
背景色
-------------------------- */
.u-bg-light {
  background-color: #a2a2a2;
}

.u-bg-white {
  background-color: #fff;
}

.u-bg-black {
  background-color: #333;
}

.u-bg-slope {
  position: relative;
  z-index: 0;
}

.u-bg-slope::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /*四角形を傾けます*/
  transform: skewY(5deg);
  transform-origin: top right;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #ddd;
}

/* --------------------------
枠線
-------------------------- */
.u-border-bottom {
  border-bottom: 1px solid #333;
}

.u-border-top {
  border-top: 1px solid #333;
}

.u-border-right {
  border-right: 1px solid #333;
}

.u-border-left {
  border-left: 1px solid #333;
}

.u-border--white {
  border-color: #fff;
}

/* --------------------------
Float解除
-------------------------- */
.u-clearfix::after {
  content: "";
  clear: both;
  font-size: 0;
  height: 0;
  display: block;
  visibility: hidden;
}

/* --------------------------
グリッド
-------------------------- */
.u-row {
  margin: -32px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .u-row {
    margin: -10px;
  }
}

.u-row-align-center {
  align-items: center;
}

.u-row-content-center {
  justify-content: center;
}

.u-row-reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  .u-row-reverse--sp {
    flex-direction: column-reverse;
  }
}

.u-row--nomargin {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .u-row--nomargin--sp {
    margin: 0;
  }
}

.u-row--narrow {
  margin: -20px;
}

@media screen and (max-width: 767px) {
  .u-row--narrow {
    margin: -12px;
  }
}

.u-col-1 {
  width: 8.3333333333%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-1 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-1 {
  margin-left: 8.3333333333%;
}

.u-row--nomargin > .u-col-1 {
  padding: 0;
}

.u-row--narrow > .u-col-1 {
  padding: 20px;
}

.u-col-2 {
  width: 16.6666666667%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-2 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-2 {
  margin-left: 16.6666666667%;
}

.u-row--nomargin > .u-col-2 {
  padding: 0;
}

.u-row--narrow > .u-col-2 {
  padding: 20px;
}

.u-col-3 {
  width: 25%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-3 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-3 {
  margin-left: 25%;
}

.u-row--nomargin > .u-col-3 {
  padding: 0;
}

.u-row--narrow > .u-col-3 {
  padding: 20px;
}

.u-col-4 {
  width: 33.3333333333%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-4 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-4 {
  margin-left: 33.3333333333%;
}

.u-row--nomargin > .u-col-4 {
  padding: 0;
}

.u-row--narrow > .u-col-4 {
  padding: 20px;
}

.u-col-5 {
  width: 41.6666666667%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-5 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-5 {
  margin-left: 41.6666666667%;
}

.u-row--nomargin > .u-col-5 {
  padding: 0;
}

.u-row--narrow > .u-col-5 {
  padding: 20px;
}

.u-col-6 {
  width: 50%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-6 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-6 {
  margin-left: 50%;
}

.u-row--nomargin > .u-col-6 {
  padding: 0;
}

.u-row--narrow > .u-col-6 {
  padding: 20px;
}

.u-col-7 {
  width: 58.3333333333%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-7 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-7 {
  margin-left: 58.3333333333%;
}

.u-row--nomargin > .u-col-7 {
  padding: 0;
}

.u-row--narrow > .u-col-7 {
  padding: 20px;
}

.u-col-8 {
  width: 66.6666666667%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-8 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-8 {
  margin-left: 66.6666666667%;
}

.u-row--nomargin > .u-col-8 {
  padding: 0;
}

.u-row--narrow > .u-col-8 {
  padding: 20px;
}

.u-col-9 {
  width: 75%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-9 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-9 {
  margin-left: 75%;
}

.u-row--nomargin > .u-col-9 {
  padding: 0;
}

.u-row--narrow > .u-col-9 {
  padding: 20px;
}

.u-col-10 {
  width: 83.3333333333%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-10 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-10 {
  margin-left: 83.3333333333%;
}

.u-row--nomargin > .u-col-10 {
  padding: 0;
}

.u-row--narrow > .u-col-10 {
  padding: 20px;
}

.u-col-11 {
  width: 91.6666666667%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-11 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-11 {
  margin-left: 91.6666666667%;
}

.u-row--nomargin > .u-col-11 {
  padding: 0;
}

.u-row--narrow > .u-col-11 {
  padding: 20px;
}

.u-col-12 {
  width: 100%;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .u-col-12 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-12 {
  margin-left: 100%;
}

.u-row--nomargin > .u-col-12 {
  padding: 0;
}

.u-row--narrow > .u-col-12 {
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .u-col-1--pc {
    width: 8.3333333333%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-1--pc {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin > .u-col-1--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-1--pc {
    padding: 20px;
  }
  .u-col-2--pc {
    width: 16.6666666667%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-2--pc {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin > .u-col-2--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-2--pc {
    padding: 20px;
  }
  .u-col-3--pc {
    width: 25%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-3--pc {
    margin-left: 25%;
  }
  .u-row--nomargin > .u-col-3--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-3--pc {
    padding: 20px;
  }
  .u-col-4--pc {
    width: 33.3333333333%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-4--pc {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin > .u-col-4--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-4--pc {
    padding: 20px;
  }
  .u-col-5--pc {
    width: 41.6666666667%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-5--pc {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin > .u-col-5--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-5--pc {
    padding: 20px;
  }
  .u-col-6--pc {
    width: 50%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-6--pc {
    margin-left: 50%;
  }
  .u-row--nomargin > .u-col-6--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-6--pc {
    padding: 20px;
  }
  .u-col-7--pc {
    width: 58.3333333333%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-7--pc {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin > .u-col-7--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-7--pc {
    padding: 20px;
  }
  .u-col-8--pc {
    width: 66.6666666667%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-8--pc {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin > .u-col-8--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-8--pc {
    padding: 20px;
  }
  .u-col-9--pc {
    width: 75%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-9--pc {
    margin-left: 75%;
  }
  .u-row--nomargin > .u-col-9--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-9--pc {
    padding: 20px;
  }
  .u-col-10--pc {
    width: 83.3333333333%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-10--pc {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin > .u-col-10--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-10--pc {
    padding: 20px;
  }
  .u-col-11--pc {
    width: 91.6666666667%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-11--pc {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin > .u-col-11--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-11--pc {
    padding: 20px;
  }
  .u-col-12--pc {
    width: 100%;
    padding: 32px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-12--pc {
    margin-left: 100%;
  }
  .u-row--nomargin > .u-col-12--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-12--pc {
    padding: 20px;
  }
}

@media screen and (max-width: 1180px) {
  .u-col-1--laptop {
    width: 8.3333333333%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-1--laptop {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin > .u-col-1--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-1--laptop {
    padding: 20px;
  }
  .u-col-2--laptop {
    width: 16.6666666667%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-2--laptop {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin > .u-col-2--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-2--laptop {
    padding: 20px;
  }
  .u-col-3--laptop {
    width: 25%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-3--laptop {
    margin-left: 25%;
  }
  .u-row--nomargin > .u-col-3--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-3--laptop {
    padding: 20px;
  }
  .u-col-4--laptop {
    width: 33.3333333333%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-4--laptop {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin > .u-col-4--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-4--laptop {
    padding: 20px;
  }
  .u-col-5--laptop {
    width: 41.6666666667%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-5--laptop {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin > .u-col-5--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-5--laptop {
    padding: 20px;
  }
  .u-col-6--laptop {
    width: 50%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-6--laptop {
    margin-left: 50%;
  }
  .u-row--nomargin > .u-col-6--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-6--laptop {
    padding: 20px;
  }
  .u-col-7--laptop {
    width: 58.3333333333%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-7--laptop {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin > .u-col-7--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-7--laptop {
    padding: 20px;
  }
  .u-col-8--laptop {
    width: 66.6666666667%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-8--laptop {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin > .u-col-8--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-8--laptop {
    padding: 20px;
  }
  .u-col-9--laptop {
    width: 75%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-9--laptop {
    margin-left: 75%;
  }
  .u-row--nomargin > .u-col-9--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-9--laptop {
    padding: 20px;
  }
  .u-col-10--laptop {
    width: 83.3333333333%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-10--laptop {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin > .u-col-10--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-10--laptop {
    padding: 20px;
  }
  .u-col-11--laptop {
    width: 91.6666666667%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-11--laptop {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin > .u-col-11--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-11--laptop {
    padding: 20px;
  }
  .u-col-12--laptop {
    width: 100%;
    padding: 32px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-12--laptop {
    margin-left: 100%;
  }
  .u-row--nomargin > .u-col-12--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-12--laptop {
    padding: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .u-col-1--tab {
    width: 8.3333333333%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-1--tab {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin > .u-col-1--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-1--tab {
    padding: 10px;
  }
  .u-col-2--tab {
    width: 16.6666666667%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-2--tab {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin > .u-col-2--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-2--tab {
    padding: 10px;
  }
  .u-col-3--tab {
    width: 25%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-3--tab {
    margin-left: 25%;
  }
  .u-row--nomargin > .u-col-3--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-3--tab {
    padding: 10px;
  }
  .u-col-4--tab {
    width: 33.3333333333%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-4--tab {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin > .u-col-4--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-4--tab {
    padding: 10px;
  }
  .u-col-5--tab {
    width: 41.6666666667%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-5--tab {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin > .u-col-5--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-5--tab {
    padding: 10px;
  }
  .u-col-6--tab {
    width: 50%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-6--tab {
    margin-left: 50%;
  }
  .u-row--nomargin > .u-col-6--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-6--tab {
    padding: 10px;
  }
  .u-col-7--tab {
    width: 58.3333333333%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-7--tab {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin > .u-col-7--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-7--tab {
    padding: 10px;
  }
  .u-col-8--tab {
    width: 66.6666666667%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-8--tab {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin > .u-col-8--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-8--tab {
    padding: 10px;
  }
  .u-col-9--tab {
    width: 75%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-9--tab {
    margin-left: 75%;
  }
  .u-row--nomargin > .u-col-9--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-9--tab {
    padding: 10px;
  }
  .u-col-10--tab {
    width: 83.3333333333%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-10--tab {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin > .u-col-10--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-10--tab {
    padding: 10px;
  }
  .u-col-11--tab {
    width: 91.6666666667%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-11--tab {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin > .u-col-11--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-11--tab {
    padding: 10px;
  }
  .u-col-12--tab {
    width: 100%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-12--tab {
    margin-left: 100%;
  }
  .u-row--nomargin > .u-col-12--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-12--tab {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-1--sp {
    width: 8.3333333333%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-1--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-1--sp {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin > .u-col-1--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-1--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-1--sp {
    padding: 12px;
  }
  .u-col-2--sp {
    width: 16.6666666667%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-2--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-2--sp {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin > .u-col-2--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-2--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-2--sp {
    padding: 12px;
  }
  .u-col-3--sp {
    width: 25%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-3--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-3--sp {
    margin-left: 25%;
  }
  .u-row--nomargin > .u-col-3--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-3--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-3--sp {
    padding: 12px;
  }
  .u-col-4--sp {
    width: 33.3333333333%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-4--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-4--sp {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin > .u-col-4--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-4--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-4--sp {
    padding: 12px;
  }
  .u-col-5--sp {
    width: 41.6666666667%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-5--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-5--sp {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin > .u-col-5--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-5--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-5--sp {
    padding: 12px;
  }
  .u-col-6--sp {
    width: 50%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-6--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-6--sp {
    margin-left: 50%;
  }
  .u-row--nomargin > .u-col-6--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-6--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-6--sp {
    padding: 12px;
  }
  .u-col-7--sp {
    width: 58.3333333333%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-7--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-7--sp {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin > .u-col-7--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-7--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-7--sp {
    padding: 12px;
  }
  .u-col-8--sp {
    width: 66.6666666667%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-8--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-8--sp {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin > .u-col-8--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-8--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-8--sp {
    padding: 12px;
  }
  .u-col-9--sp {
    width: 75%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-9--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-9--sp {
    margin-left: 75%;
  }
  .u-row--nomargin > .u-col-9--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-9--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-9--sp {
    padding: 12px;
  }
  .u-col-10--sp {
    width: 83.3333333333%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-10--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-10--sp {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin > .u-col-10--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-10--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-10--sp {
    padding: 12px;
  }
  .u-col-11--sp {
    width: 91.6666666667%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-11--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-11--sp {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin > .u-col-11--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-11--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-11--sp {
    padding: 12px;
  }
  .u-col-12--sp {
    width: 100%;
    padding: 32px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-12--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-12--sp {
    margin-left: 100%;
  }
  .u-row--nomargin > .u-col-12--sp {
    padding: 0;
  }
  .u-row--nomargin--sp > .u-col-12--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-12--sp {
    padding: 12px;
  }
}

/* --------------------------
非表示切り替え
-------------------------- */
@media screen and (min-width: 768px) {
  .u-hide--pc {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .u-hide--tab {
    display: none;
  }
}

@media screen and (max-width: 1180px) {
  .u-hide--laptop {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .u-hide--sp {
    display: none;
  }
}

.u-block {
  display: block;
}

/* --------------------------
margin、padding指定
-------------------------- */
.u-mt0 {
  margin-top: 0px !important;
}

.u-mr0 {
  margin-right: 0px !important;
}

.u-ml0 {
  margin-left: 0px !important;
}

.u-mb0 {
  margin-bottom: 0px !important;
}

.u-pt0 {
  padding-top: 0px !important;
}

.u-pr0 {
  padding-right: 0px !important;
}

.u-pl0 {
  padding-left: 0px !important;
}

.u-pb0 {
  padding-bottom: 0px !important;
}

.u-mt5 {
  margin-top: 5px !important;
}

.u-mr5 {
  margin-right: 5px !important;
}

.u-ml5 {
  margin-left: 5px !important;
}

.u-mb5 {
  margin-bottom: 5px !important;
}

.u-pt5 {
  padding-top: 5px !important;
}

.u-pr5 {
  padding-right: 5px !important;
}

.u-pl5 {
  padding-left: 5px !important;
}

.u-pb5 {
  padding-bottom: 5px !important;
}

.u-mt10 {
  margin-top: 10px !important;
}

.u-mr10 {
  margin-right: 10px !important;
}

.u-ml10 {
  margin-left: 10px !important;
}

.u-mb10 {
  margin-bottom: 10px !important;
}

.u-pt10 {
  padding-top: 10px !important;
}

.u-pr10 {
  padding-right: 10px !important;
}

.u-pl10 {
  padding-left: 10px !important;
}

.u-pb10 {
  padding-bottom: 10px !important;
}

.u-mt15 {
  margin-top: 15px !important;
}

.u-mr15 {
  margin-right: 15px !important;
}

.u-ml15 {
  margin-left: 15px !important;
}

.u-mb15 {
  margin-bottom: 15px !important;
}

.u-pt15 {
  padding-top: 15px !important;
}

.u-pr15 {
  padding-right: 15px !important;
}

.u-pl15 {
  padding-left: 15px !important;
}

.u-pb15 {
  padding-bottom: 15px !important;
}

.u-mt20 {
  margin-top: 20px !important;
}

.u-mr20 {
  margin-right: 20px !important;
}

.u-ml20 {
  margin-left: 20px !important;
}

.u-mb20 {
  margin-bottom: 20px !important;
}

.u-pt20 {
  padding-top: 20px !important;
}

.u-pr20 {
  padding-right: 20px !important;
}

.u-pl20 {
  padding-left: 20px !important;
}

.u-pb20 {
  padding-bottom: 20px !important;
}

.u-mt25 {
  margin-top: 25px !important;
}

.u-mr25 {
  margin-right: 25px !important;
}

.u-ml25 {
  margin-left: 25px !important;
}

.u-mb25 {
  margin-bottom: 25px !important;
}

.u-pt25 {
  padding-top: 25px !important;
}

.u-pr25 {
  padding-right: 25px !important;
}

.u-pl25 {
  padding-left: 25px !important;
}

.u-pb25 {
  padding-bottom: 25px !important;
}

.u-mt30 {
  margin-top: 30px !important;
}

.u-mr30 {
  margin-right: 30px !important;
}

.u-ml30 {
  margin-left: 30px !important;
}

.u-mb30 {
  margin-bottom: 30px !important;
}

.u-pt30 {
  padding-top: 30px !important;
}

.u-pr30 {
  padding-right: 30px !important;
}

.u-pl30 {
  padding-left: 30px !important;
}

.u-pb30 {
  padding-bottom: 30px !important;
}

.u-mt35 {
  margin-top: 35px !important;
}

.u-mr35 {
  margin-right: 35px !important;
}

.u-ml35 {
  margin-left: 35px !important;
}

.u-mb35 {
  margin-bottom: 35px !important;
}

.u-pt35 {
  padding-top: 35px !important;
}

.u-pr35 {
  padding-right: 35px !important;
}

.u-pl35 {
  padding-left: 35px !important;
}

.u-pb35 {
  padding-bottom: 35px !important;
}

.u-mt40 {
  margin-top: 40px !important;
}

.u-mr40 {
  margin-right: 40px !important;
}

.u-ml40 {
  margin-left: 40px !important;
}

.u-mb40 {
  margin-bottom: 40px !important;
}

.u-pt40 {
  padding-top: 40px !important;
}

.u-pr40 {
  padding-right: 40px !important;
}

.u-pl40 {
  padding-left: 40px !important;
}

.u-pb40 {
  padding-bottom: 40px !important;
}

.u-mt45 {
  margin-top: 45px !important;
}

.u-mr45 {
  margin-right: 45px !important;
}

.u-ml45 {
  margin-left: 45px !important;
}

.u-mb45 {
  margin-bottom: 45px !important;
}

.u-pt45 {
  padding-top: 45px !important;
}

.u-pr45 {
  padding-right: 45px !important;
}

.u-pl45 {
  padding-left: 45px !important;
}

.u-pb45 {
  padding-bottom: 45px !important;
}

.u-mt50 {
  margin-top: 50px !important;
}

.u-mr50 {
  margin-right: 50px !important;
}

.u-ml50 {
  margin-left: 50px !important;
}

.u-mb50 {
  margin-bottom: 50px !important;
}

.u-pt50 {
  padding-top: 50px !important;
}

.u-pr50 {
  padding-right: 50px !important;
}

.u-pl50 {
  padding-left: 50px !important;
}

.u-pb50 {
  padding-bottom: 50px !important;
}

.u-mt55 {
  margin-top: 55px !important;
}

.u-mr55 {
  margin-right: 55px !important;
}

.u-ml55 {
  margin-left: 55px !important;
}

.u-mb55 {
  margin-bottom: 55px !important;
}

.u-pt55 {
  padding-top: 55px !important;
}

.u-pr55 {
  padding-right: 55px !important;
}

.u-pl55 {
  padding-left: 55px !important;
}

.u-pb55 {
  padding-bottom: 55px !important;
}

.u-mt60 {
  margin-top: 60px !important;
}

.u-mr60 {
  margin-right: 60px !important;
}

.u-ml60 {
  margin-left: 60px !important;
}

.u-mb60 {
  margin-bottom: 60px !important;
}

.u-pt60 {
  padding-top: 60px !important;
}

.u-pr60 {
  padding-right: 60px !important;
}

.u-pl60 {
  padding-left: 60px !important;
}

.u-pb60 {
  padding-bottom: 60px !important;
}

.u-mt65 {
  margin-top: 65px !important;
}

.u-mr65 {
  margin-right: 65px !important;
}

.u-ml65 {
  margin-left: 65px !important;
}

.u-mb65 {
  margin-bottom: 65px !important;
}

.u-pt65 {
  padding-top: 65px !important;
}

.u-pr65 {
  padding-right: 65px !important;
}

.u-pl65 {
  padding-left: 65px !important;
}

.u-pb65 {
  padding-bottom: 65px !important;
}

.u-mt70 {
  margin-top: 70px !important;
}

.u-mr70 {
  margin-right: 70px !important;
}

.u-ml70 {
  margin-left: 70px !important;
}

.u-mb70 {
  margin-bottom: 70px !important;
}

.u-pt70 {
  padding-top: 70px !important;
}

.u-pr70 {
  padding-right: 70px !important;
}

.u-pl70 {
  padding-left: 70px !important;
}

.u-pb70 {
  padding-bottom: 70px !important;
}

.u-mt75 {
  margin-top: 75px !important;
}

.u-mr75 {
  margin-right: 75px !important;
}

.u-ml75 {
  margin-left: 75px !important;
}

.u-mb75 {
  margin-bottom: 75px !important;
}

.u-pt75 {
  padding-top: 75px !important;
}

.u-pr75 {
  padding-right: 75px !important;
}

.u-pl75 {
  padding-left: 75px !important;
}

.u-pb75 {
  padding-bottom: 75px !important;
}

.u-mt80 {
  margin-top: 80px !important;
}

.u-mr80 {
  margin-right: 80px !important;
}

.u-ml80 {
  margin-left: 80px !important;
}

.u-mb80 {
  margin-bottom: 80px !important;
}

.u-pt80 {
  padding-top: 80px !important;
}

.u-pr80 {
  padding-right: 80px !important;
}

.u-pl80 {
  padding-left: 80px !important;
}

.u-pb80 {
  padding-bottom: 80px !important;
}

.u-mt85 {
  margin-top: 85px !important;
}

.u-mr85 {
  margin-right: 85px !important;
}

.u-ml85 {
  margin-left: 85px !important;
}

.u-mb85 {
  margin-bottom: 85px !important;
}

.u-pt85 {
  padding-top: 85px !important;
}

.u-pr85 {
  padding-right: 85px !important;
}

.u-pl85 {
  padding-left: 85px !important;
}

.u-pb85 {
  padding-bottom: 85px !important;
}

.u-mt90 {
  margin-top: 90px !important;
}

.u-mr90 {
  margin-right: 90px !important;
}

.u-ml90 {
  margin-left: 90px !important;
}

.u-mb90 {
  margin-bottom: 90px !important;
}

.u-pt90 {
  padding-top: 90px !important;
}

.u-pr90 {
  padding-right: 90px !important;
}

.u-pl90 {
  padding-left: 90px !important;
}

.u-pb90 {
  padding-bottom: 90px !important;
}

.u-mt95 {
  margin-top: 95px !important;
}

.u-mr95 {
  margin-right: 95px !important;
}

.u-ml95 {
  margin-left: 95px !important;
}

.u-mb95 {
  margin-bottom: 95px !important;
}

.u-pt95 {
  padding-top: 95px !important;
}

.u-pr95 {
  padding-right: 95px !important;
}

.u-pl95 {
  padding-left: 95px !important;
}

.u-pb95 {
  padding-bottom: 95px !important;
}

.u-mt100 {
  margin-top: 100px !important;
}

.u-mr100 {
  margin-right: 100px !important;
}

.u-ml100 {
  margin-left: 100px !important;
}

.u-mb100 {
  margin-bottom: 100px !important;
}

.u-pt100 {
  padding-top: 100px !important;
}

.u-pr100 {
  padding-right: 100px !important;
}

.u-pl100 {
  padding-left: 100px !important;
}

.u-pb100 {
  padding-bottom: 100px !important;
}

@media screen and (max-width: 767px) {
  .u-mt0--sp {
    margin-top: 0px !important;
  }
  .u-mr0--sp {
    margin-right: 0px !important;
  }
  .u-ml0--sp {
    margin-left: 0px !important;
  }
  .u-mb0--sp {
    margin-bottom: 0px !important;
  }
  .u-pt0--sp {
    padding-top: 0px !important;
  }
  .u-pr0--sp {
    padding-right: 0px !important;
  }
  .u-pl0--sp {
    padding-left: 0px !important;
  }
  .u-pb0--sp {
    padding-bottom: 0px !important;
  }
  .u-mt5--sp {
    margin-top: 5px !important;
  }
  .u-mr5--sp {
    margin-right: 5px !important;
  }
  .u-ml5--sp {
    margin-left: 5px !important;
  }
  .u-mb5--sp {
    margin-bottom: 5px !important;
  }
  .u-pt5--sp {
    padding-top: 5px !important;
  }
  .u-pr5--sp {
    padding-right: 5px !important;
  }
  .u-pl5--sp {
    padding-left: 5px !important;
  }
  .u-pb5--sp {
    padding-bottom: 5px !important;
  }
  .u-mt10--sp {
    margin-top: 10px !important;
  }
  .u-mr10--sp {
    margin-right: 10px !important;
  }
  .u-ml10--sp {
    margin-left: 10px !important;
  }
  .u-mb10--sp {
    margin-bottom: 10px !important;
  }
  .u-pt10--sp {
    padding-top: 10px !important;
  }
  .u-pr10--sp {
    padding-right: 10px !important;
  }
  .u-pl10--sp {
    padding-left: 10px !important;
  }
  .u-pb10--sp {
    padding-bottom: 10px !important;
  }
  .u-mt15--sp {
    margin-top: 15px !important;
  }
  .u-mr15--sp {
    margin-right: 15px !important;
  }
  .u-ml15--sp {
    margin-left: 15px !important;
  }
  .u-mb15--sp {
    margin-bottom: 15px !important;
  }
  .u-pt15--sp {
    padding-top: 15px !important;
  }
  .u-pr15--sp {
    padding-right: 15px !important;
  }
  .u-pl15--sp {
    padding-left: 15px !important;
  }
  .u-pb15--sp {
    padding-bottom: 15px !important;
  }
  .u-mt20--sp {
    margin-top: 20px !important;
  }
  .u-mr20--sp {
    margin-right: 20px !important;
  }
  .u-ml20--sp {
    margin-left: 20px !important;
  }
  .u-mb20--sp {
    margin-bottom: 20px !important;
  }
  .u-pt20--sp {
    padding-top: 20px !important;
  }
  .u-pr20--sp {
    padding-right: 20px !important;
  }
  .u-pl20--sp {
    padding-left: 20px !important;
  }
  .u-pb20--sp {
    padding-bottom: 20px !important;
  }
  .u-mt25--sp {
    margin-top: 25px !important;
  }
  .u-mr25--sp {
    margin-right: 25px !important;
  }
  .u-ml25--sp {
    margin-left: 25px !important;
  }
  .u-mb25--sp {
    margin-bottom: 25px !important;
  }
  .u-pt25--sp {
    padding-top: 25px !important;
  }
  .u-pr25--sp {
    padding-right: 25px !important;
  }
  .u-pl25--sp {
    padding-left: 25px !important;
  }
  .u-pb25--sp {
    padding-bottom: 25px !important;
  }
  .u-mt30--sp {
    margin-top: 30px !important;
  }
  .u-mr30--sp {
    margin-right: 30px !important;
  }
  .u-ml30--sp {
    margin-left: 30px !important;
  }
  .u-mb30--sp {
    margin-bottom: 30px !important;
  }
  .u-pt30--sp {
    padding-top: 30px !important;
  }
  .u-pr30--sp {
    padding-right: 30px !important;
  }
  .u-pl30--sp {
    padding-left: 30px !important;
  }
  .u-pb30--sp {
    padding-bottom: 30px !important;
  }
  .u-mt35--sp {
    margin-top: 35px !important;
  }
  .u-mr35--sp {
    margin-right: 35px !important;
  }
  .u-ml35--sp {
    margin-left: 35px !important;
  }
  .u-mb35--sp {
    margin-bottom: 35px !important;
  }
  .u-pt35--sp {
    padding-top: 35px !important;
  }
  .u-pr35--sp {
    padding-right: 35px !important;
  }
  .u-pl35--sp {
    padding-left: 35px !important;
  }
  .u-pb35--sp {
    padding-bottom: 35px !important;
  }
  .u-mt40--sp {
    margin-top: 40px !important;
  }
  .u-mr40--sp {
    margin-right: 40px !important;
  }
  .u-ml40--sp {
    margin-left: 40px !important;
  }
  .u-mb40--sp {
    margin-bottom: 40px !important;
  }
  .u-pt40--sp {
    padding-top: 40px !important;
  }
  .u-pr40--sp {
    padding-right: 40px !important;
  }
  .u-pl40--sp {
    padding-left: 40px !important;
  }
  .u-pb40--sp {
    padding-bottom: 40px !important;
  }
  .u-mt45--sp {
    margin-top: 45px !important;
  }
  .u-mr45--sp {
    margin-right: 45px !important;
  }
  .u-ml45--sp {
    margin-left: 45px !important;
  }
  .u-mb45--sp {
    margin-bottom: 45px !important;
  }
  .u-pt45--sp {
    padding-top: 45px !important;
  }
  .u-pr45--sp {
    padding-right: 45px !important;
  }
  .u-pl45--sp {
    padding-left: 45px !important;
  }
  .u-pb45--sp {
    padding-bottom: 45px !important;
  }
  .u-mt50--sp {
    margin-top: 50px !important;
  }
  .u-mr50--sp {
    margin-right: 50px !important;
  }
  .u-ml50--sp {
    margin-left: 50px !important;
  }
  .u-mb50--sp {
    margin-bottom: 50px !important;
  }
  .u-pt50--sp {
    padding-top: 50px !important;
  }
  .u-pr50--sp {
    padding-right: 50px !important;
  }
  .u-pl50--sp {
    padding-left: 50px !important;
  }
  .u-pb50--sp {
    padding-bottom: 50px !important;
  }
  .u-mt55--sp {
    margin-top: 55px !important;
  }
  .u-mr55--sp {
    margin-right: 55px !important;
  }
  .u-ml55--sp {
    margin-left: 55px !important;
  }
  .u-mb55--sp {
    margin-bottom: 55px !important;
  }
  .u-pt55--sp {
    padding-top: 55px !important;
  }
  .u-pr55--sp {
    padding-right: 55px !important;
  }
  .u-pl55--sp {
    padding-left: 55px !important;
  }
  .u-pb55--sp {
    padding-bottom: 55px !important;
  }
  .u-mt60--sp {
    margin-top: 60px !important;
  }
  .u-mr60--sp {
    margin-right: 60px !important;
  }
  .u-ml60--sp {
    margin-left: 60px !important;
  }
  .u-mb60--sp {
    margin-bottom: 60px !important;
  }
  .u-pt60--sp {
    padding-top: 60px !important;
  }
  .u-pr60--sp {
    padding-right: 60px !important;
  }
  .u-pl60--sp {
    padding-left: 60px !important;
  }
  .u-pb60--sp {
    padding-bottom: 60px !important;
  }
  .u-mt65--sp {
    margin-top: 65px !important;
  }
  .u-mr65--sp {
    margin-right: 65px !important;
  }
  .u-ml65--sp {
    margin-left: 65px !important;
  }
  .u-mb65--sp {
    margin-bottom: 65px !important;
  }
  .u-pt65--sp {
    padding-top: 65px !important;
  }
  .u-pr65--sp {
    padding-right: 65px !important;
  }
  .u-pl65--sp {
    padding-left: 65px !important;
  }
  .u-pb65--sp {
    padding-bottom: 65px !important;
  }
  .u-mt70--sp {
    margin-top: 70px !important;
  }
  .u-mr70--sp {
    margin-right: 70px !important;
  }
  .u-ml70--sp {
    margin-left: 70px !important;
  }
  .u-mb70--sp {
    margin-bottom: 70px !important;
  }
  .u-pt70--sp {
    padding-top: 70px !important;
  }
  .u-pr70--sp {
    padding-right: 70px !important;
  }
  .u-pl70--sp {
    padding-left: 70px !important;
  }
  .u-pb70--sp {
    padding-bottom: 70px !important;
  }
  .u-mt75--sp {
    margin-top: 75px !important;
  }
  .u-mr75--sp {
    margin-right: 75px !important;
  }
  .u-ml75--sp {
    margin-left: 75px !important;
  }
  .u-mb75--sp {
    margin-bottom: 75px !important;
  }
  .u-pt75--sp {
    padding-top: 75px !important;
  }
  .u-pr75--sp {
    padding-right: 75px !important;
  }
  .u-pl75--sp {
    padding-left: 75px !important;
  }
  .u-pb75--sp {
    padding-bottom: 75px !important;
  }
  .u-mt80--sp {
    margin-top: 80px !important;
  }
  .u-mr80--sp {
    margin-right: 80px !important;
  }
  .u-ml80--sp {
    margin-left: 80px !important;
  }
  .u-mb80--sp {
    margin-bottom: 80px !important;
  }
  .u-pt80--sp {
    padding-top: 80px !important;
  }
  .u-pr80--sp {
    padding-right: 80px !important;
  }
  .u-pl80--sp {
    padding-left: 80px !important;
  }
  .u-pb80--sp {
    padding-bottom: 80px !important;
  }
  .u-mt85--sp {
    margin-top: 85px !important;
  }
  .u-mr85--sp {
    margin-right: 85px !important;
  }
  .u-ml85--sp {
    margin-left: 85px !important;
  }
  .u-mb85--sp {
    margin-bottom: 85px !important;
  }
  .u-pt85--sp {
    padding-top: 85px !important;
  }
  .u-pr85--sp {
    padding-right: 85px !important;
  }
  .u-pl85--sp {
    padding-left: 85px !important;
  }
  .u-pb85--sp {
    padding-bottom: 85px !important;
  }
  .u-mt90--sp {
    margin-top: 90px !important;
  }
  .u-mr90--sp {
    margin-right: 90px !important;
  }
  .u-ml90--sp {
    margin-left: 90px !important;
  }
  .u-mb90--sp {
    margin-bottom: 90px !important;
  }
  .u-pt90--sp {
    padding-top: 90px !important;
  }
  .u-pr90--sp {
    padding-right: 90px !important;
  }
  .u-pl90--sp {
    padding-left: 90px !important;
  }
  .u-pb90--sp {
    padding-bottom: 90px !important;
  }
  .u-mt95--sp {
    margin-top: 95px !important;
  }
  .u-mr95--sp {
    margin-right: 95px !important;
  }
  .u-ml95--sp {
    margin-left: 95px !important;
  }
  .u-mb95--sp {
    margin-bottom: 95px !important;
  }
  .u-pt95--sp {
    padding-top: 95px !important;
  }
  .u-pr95--sp {
    padding-right: 95px !important;
  }
  .u-pl95--sp {
    padding-left: 95px !important;
  }
  .u-pb95--sp {
    padding-bottom: 95px !important;
  }
  .u-mt100--sp {
    margin-top: 100px !important;
  }
  .u-mr100--sp {
    margin-right: 100px !important;
  }
  .u-ml100--sp {
    margin-left: 100px !important;
  }
  .u-mb100--sp {
    margin-bottom: 100px !important;
  }
  .u-pt100--sp {
    padding-top: 100px !important;
  }
  .u-pr100--sp {
    padding-right: 100px !important;
  }
  .u-pl100--sp {
    padding-left: 100px !important;
  }
  .u-pb100--sp {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 1024px) {
  .u-mt0--tab {
    margin-top: 0px !important;
  }
  .u-mr0--tab {
    margin-right: 0px !important;
  }
  .u-ml0--tab {
    margin-left: 0px !important;
  }
  .u-mb0--tab {
    margin-bottom: 0px !important;
  }
  .u-pt0--tab {
    padding-top: 0px !important;
  }
  .u-pr0--tab {
    padding-right: 0px !important;
  }
  .u-pl0--tab {
    padding-left: 0px !important;
  }
  .u-pb0--tab {
    padding-bottom: 0px !important;
  }
  .u-mt5--tab {
    margin-top: 5px !important;
  }
  .u-mr5--tab {
    margin-right: 5px !important;
  }
  .u-ml5--tab {
    margin-left: 5px !important;
  }
  .u-mb5--tab {
    margin-bottom: 5px !important;
  }
  .u-pt5--tab {
    padding-top: 5px !important;
  }
  .u-pr5--tab {
    padding-right: 5px !important;
  }
  .u-pl5--tab {
    padding-left: 5px !important;
  }
  .u-pb5--tab {
    padding-bottom: 5px !important;
  }
  .u-mt10--tab {
    margin-top: 10px !important;
  }
  .u-mr10--tab {
    margin-right: 10px !important;
  }
  .u-ml10--tab {
    margin-left: 10px !important;
  }
  .u-mb10--tab {
    margin-bottom: 10px !important;
  }
  .u-pt10--tab {
    padding-top: 10px !important;
  }
  .u-pr10--tab {
    padding-right: 10px !important;
  }
  .u-pl10--tab {
    padding-left: 10px !important;
  }
  .u-pb10--tab {
    padding-bottom: 10px !important;
  }
  .u-mt15--tab {
    margin-top: 15px !important;
  }
  .u-mr15--tab {
    margin-right: 15px !important;
  }
  .u-ml15--tab {
    margin-left: 15px !important;
  }
  .u-mb15--tab {
    margin-bottom: 15px !important;
  }
  .u-pt15--tab {
    padding-top: 15px !important;
  }
  .u-pr15--tab {
    padding-right: 15px !important;
  }
  .u-pl15--tab {
    padding-left: 15px !important;
  }
  .u-pb15--tab {
    padding-bottom: 15px !important;
  }
  .u-mt20--tab {
    margin-top: 20px !important;
  }
  .u-mr20--tab {
    margin-right: 20px !important;
  }
  .u-ml20--tab {
    margin-left: 20px !important;
  }
  .u-mb20--tab {
    margin-bottom: 20px !important;
  }
  .u-pt20--tab {
    padding-top: 20px !important;
  }
  .u-pr20--tab {
    padding-right: 20px !important;
  }
  .u-pl20--tab {
    padding-left: 20px !important;
  }
  .u-pb20--tab {
    padding-bottom: 20px !important;
  }
  .u-mt25--tab {
    margin-top: 25px !important;
  }
  .u-mr25--tab {
    margin-right: 25px !important;
  }
  .u-ml25--tab {
    margin-left: 25px !important;
  }
  .u-mb25--tab {
    margin-bottom: 25px !important;
  }
  .u-pt25--tab {
    padding-top: 25px !important;
  }
  .u-pr25--tab {
    padding-right: 25px !important;
  }
  .u-pl25--tab {
    padding-left: 25px !important;
  }
  .u-pb25--tab {
    padding-bottom: 25px !important;
  }
  .u-mt30--tab {
    margin-top: 30px !important;
  }
  .u-mr30--tab {
    margin-right: 30px !important;
  }
  .u-ml30--tab {
    margin-left: 30px !important;
  }
  .u-mb30--tab {
    margin-bottom: 30px !important;
  }
  .u-pt30--tab {
    padding-top: 30px !important;
  }
  .u-pr30--tab {
    padding-right: 30px !important;
  }
  .u-pl30--tab {
    padding-left: 30px !important;
  }
  .u-pb30--tab {
    padding-bottom: 30px !important;
  }
  .u-mt35--tab {
    margin-top: 35px !important;
  }
  .u-mr35--tab {
    margin-right: 35px !important;
  }
  .u-ml35--tab {
    margin-left: 35px !important;
  }
  .u-mb35--tab {
    margin-bottom: 35px !important;
  }
  .u-pt35--tab {
    padding-top: 35px !important;
  }
  .u-pr35--tab {
    padding-right: 35px !important;
  }
  .u-pl35--tab {
    padding-left: 35px !important;
  }
  .u-pb35--tab {
    padding-bottom: 35px !important;
  }
  .u-mt40--tab {
    margin-top: 40px !important;
  }
  .u-mr40--tab {
    margin-right: 40px !important;
  }
  .u-ml40--tab {
    margin-left: 40px !important;
  }
  .u-mb40--tab {
    margin-bottom: 40px !important;
  }
  .u-pt40--tab {
    padding-top: 40px !important;
  }
  .u-pr40--tab {
    padding-right: 40px !important;
  }
  .u-pl40--tab {
    padding-left: 40px !important;
  }
  .u-pb40--tab {
    padding-bottom: 40px !important;
  }
  .u-mt45--tab {
    margin-top: 45px !important;
  }
  .u-mr45--tab {
    margin-right: 45px !important;
  }
  .u-ml45--tab {
    margin-left: 45px !important;
  }
  .u-mb45--tab {
    margin-bottom: 45px !important;
  }
  .u-pt45--tab {
    padding-top: 45px !important;
  }
  .u-pr45--tab {
    padding-right: 45px !important;
  }
  .u-pl45--tab {
    padding-left: 45px !important;
  }
  .u-pb45--tab {
    padding-bottom: 45px !important;
  }
  .u-mt50--tab {
    margin-top: 50px !important;
  }
  .u-mr50--tab {
    margin-right: 50px !important;
  }
  .u-ml50--tab {
    margin-left: 50px !important;
  }
  .u-mb50--tab {
    margin-bottom: 50px !important;
  }
  .u-pt50--tab {
    padding-top: 50px !important;
  }
  .u-pr50--tab {
    padding-right: 50px !important;
  }
  .u-pl50--tab {
    padding-left: 50px !important;
  }
  .u-pb50--tab {
    padding-bottom: 50px !important;
  }
  .u-mt55--tab {
    margin-top: 55px !important;
  }
  .u-mr55--tab {
    margin-right: 55px !important;
  }
  .u-ml55--tab {
    margin-left: 55px !important;
  }
  .u-mb55--tab {
    margin-bottom: 55px !important;
  }
  .u-pt55--tab {
    padding-top: 55px !important;
  }
  .u-pr55--tab {
    padding-right: 55px !important;
  }
  .u-pl55--tab {
    padding-left: 55px !important;
  }
  .u-pb55--tab {
    padding-bottom: 55px !important;
  }
  .u-mt60--tab {
    margin-top: 60px !important;
  }
  .u-mr60--tab {
    margin-right: 60px !important;
  }
  .u-ml60--tab {
    margin-left: 60px !important;
  }
  .u-mb60--tab {
    margin-bottom: 60px !important;
  }
  .u-pt60--tab {
    padding-top: 60px !important;
  }
  .u-pr60--tab {
    padding-right: 60px !important;
  }
  .u-pl60--tab {
    padding-left: 60px !important;
  }
  .u-pb60--tab {
    padding-bottom: 60px !important;
  }
  .u-mt65--tab {
    margin-top: 65px !important;
  }
  .u-mr65--tab {
    margin-right: 65px !important;
  }
  .u-ml65--tab {
    margin-left: 65px !important;
  }
  .u-mb65--tab {
    margin-bottom: 65px !important;
  }
  .u-pt65--tab {
    padding-top: 65px !important;
  }
  .u-pr65--tab {
    padding-right: 65px !important;
  }
  .u-pl65--tab {
    padding-left: 65px !important;
  }
  .u-pb65--tab {
    padding-bottom: 65px !important;
  }
  .u-mt70--tab {
    margin-top: 70px !important;
  }
  .u-mr70--tab {
    margin-right: 70px !important;
  }
  .u-ml70--tab {
    margin-left: 70px !important;
  }
  .u-mb70--tab {
    margin-bottom: 70px !important;
  }
  .u-pt70--tab {
    padding-top: 70px !important;
  }
  .u-pr70--tab {
    padding-right: 70px !important;
  }
  .u-pl70--tab {
    padding-left: 70px !important;
  }
  .u-pb70--tab {
    padding-bottom: 70px !important;
  }
  .u-mt75--tab {
    margin-top: 75px !important;
  }
  .u-mr75--tab {
    margin-right: 75px !important;
  }
  .u-ml75--tab {
    margin-left: 75px !important;
  }
  .u-mb75--tab {
    margin-bottom: 75px !important;
  }
  .u-pt75--tab {
    padding-top: 75px !important;
  }
  .u-pr75--tab {
    padding-right: 75px !important;
  }
  .u-pl75--tab {
    padding-left: 75px !important;
  }
  .u-pb75--tab {
    padding-bottom: 75px !important;
  }
  .u-mt80--tab {
    margin-top: 80px !important;
  }
  .u-mr80--tab {
    margin-right: 80px !important;
  }
  .u-ml80--tab {
    margin-left: 80px !important;
  }
  .u-mb80--tab {
    margin-bottom: 80px !important;
  }
  .u-pt80--tab {
    padding-top: 80px !important;
  }
  .u-pr80--tab {
    padding-right: 80px !important;
  }
  .u-pl80--tab {
    padding-left: 80px !important;
  }
  .u-pb80--tab {
    padding-bottom: 80px !important;
  }
  .u-mt85--tab {
    margin-top: 85px !important;
  }
  .u-mr85--tab {
    margin-right: 85px !important;
  }
  .u-ml85--tab {
    margin-left: 85px !important;
  }
  .u-mb85--tab {
    margin-bottom: 85px !important;
  }
  .u-pt85--tab {
    padding-top: 85px !important;
  }
  .u-pr85--tab {
    padding-right: 85px !important;
  }
  .u-pl85--tab {
    padding-left: 85px !important;
  }
  .u-pb85--tab {
    padding-bottom: 85px !important;
  }
  .u-mt90--tab {
    margin-top: 90px !important;
  }
  .u-mr90--tab {
    margin-right: 90px !important;
  }
  .u-ml90--tab {
    margin-left: 90px !important;
  }
  .u-mb90--tab {
    margin-bottom: 90px !important;
  }
  .u-pt90--tab {
    padding-top: 90px !important;
  }
  .u-pr90--tab {
    padding-right: 90px !important;
  }
  .u-pl90--tab {
    padding-left: 90px !important;
  }
  .u-pb90--tab {
    padding-bottom: 90px !important;
  }
  .u-mt95--tab {
    margin-top: 95px !important;
  }
  .u-mr95--tab {
    margin-right: 95px !important;
  }
  .u-ml95--tab {
    margin-left: 95px !important;
  }
  .u-mb95--tab {
    margin-bottom: 95px !important;
  }
  .u-pt95--tab {
    padding-top: 95px !important;
  }
  .u-pr95--tab {
    padding-right: 95px !important;
  }
  .u-pl95--tab {
    padding-left: 95px !important;
  }
  .u-pb95--tab {
    padding-bottom: 95px !important;
  }
  .u-mt100--tab {
    margin-top: 100px !important;
  }
  .u-mr100--tab {
    margin-right: 100px !important;
  }
  .u-ml100--tab {
    margin-left: 100px !important;
  }
  .u-mb100--tab {
    margin-bottom: 100px !important;
  }
  .u-pt100--tab {
    padding-top: 100px !important;
  }
  .u-pr100--tab {
    padding-right: 100px !important;
  }
  .u-pl100--tab {
    padding-left: 100px !important;
  }
  .u-pb100--tab {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 1180px) {
  .u-mt0--laptop {
    margin-top: 0px !important;
  }
  .u-mr0--laptop {
    margin-right: 0px !important;
  }
  .u-ml0--laptop {
    margin-left: 0px !important;
  }
  .u-mb0--laptop {
    margin-bottom: 0px !important;
  }
  .u-pt0--laptop {
    padding-top: 0px !important;
  }
  .u-pr0--laptop {
    padding-right: 0px !important;
  }
  .u-pl0--laptop {
    padding-left: 0px !important;
  }
  .u-pb0--laptop {
    padding-bottom: 0px !important;
  }
  .u-mt5--laptop {
    margin-top: 5px !important;
  }
  .u-mr5--laptop {
    margin-right: 5px !important;
  }
  .u-ml5--laptop {
    margin-left: 5px !important;
  }
  .u-mb5--laptop {
    margin-bottom: 5px !important;
  }
  .u-pt5--laptop {
    padding-top: 5px !important;
  }
  .u-pr5--laptop {
    padding-right: 5px !important;
  }
  .u-pl5--laptop {
    padding-left: 5px !important;
  }
  .u-pb5--laptop {
    padding-bottom: 5px !important;
  }
  .u-mt10--laptop {
    margin-top: 10px !important;
  }
  .u-mr10--laptop {
    margin-right: 10px !important;
  }
  .u-ml10--laptop {
    margin-left: 10px !important;
  }
  .u-mb10--laptop {
    margin-bottom: 10px !important;
  }
  .u-pt10--laptop {
    padding-top: 10px !important;
  }
  .u-pr10--laptop {
    padding-right: 10px !important;
  }
  .u-pl10--laptop {
    padding-left: 10px !important;
  }
  .u-pb10--laptop {
    padding-bottom: 10px !important;
  }
  .u-mt15--laptop {
    margin-top: 15px !important;
  }
  .u-mr15--laptop {
    margin-right: 15px !important;
  }
  .u-ml15--laptop {
    margin-left: 15px !important;
  }
  .u-mb15--laptop {
    margin-bottom: 15px !important;
  }
  .u-pt15--laptop {
    padding-top: 15px !important;
  }
  .u-pr15--laptop {
    padding-right: 15px !important;
  }
  .u-pl15--laptop {
    padding-left: 15px !important;
  }
  .u-pb15--laptop {
    padding-bottom: 15px !important;
  }
  .u-mt20--laptop {
    margin-top: 20px !important;
  }
  .u-mr20--laptop {
    margin-right: 20px !important;
  }
  .u-ml20--laptop {
    margin-left: 20px !important;
  }
  .u-mb20--laptop {
    margin-bottom: 20px !important;
  }
  .u-pt20--laptop {
    padding-top: 20px !important;
  }
  .u-pr20--laptop {
    padding-right: 20px !important;
  }
  .u-pl20--laptop {
    padding-left: 20px !important;
  }
  .u-pb20--laptop {
    padding-bottom: 20px !important;
  }
  .u-mt25--laptop {
    margin-top: 25px !important;
  }
  .u-mr25--laptop {
    margin-right: 25px !important;
  }
  .u-ml25--laptop {
    margin-left: 25px !important;
  }
  .u-mb25--laptop {
    margin-bottom: 25px !important;
  }
  .u-pt25--laptop {
    padding-top: 25px !important;
  }
  .u-pr25--laptop {
    padding-right: 25px !important;
  }
  .u-pl25--laptop {
    padding-left: 25px !important;
  }
  .u-pb25--laptop {
    padding-bottom: 25px !important;
  }
  .u-mt30--laptop {
    margin-top: 30px !important;
  }
  .u-mr30--laptop {
    margin-right: 30px !important;
  }
  .u-ml30--laptop {
    margin-left: 30px !important;
  }
  .u-mb30--laptop {
    margin-bottom: 30px !important;
  }
  .u-pt30--laptop {
    padding-top: 30px !important;
  }
  .u-pr30--laptop {
    padding-right: 30px !important;
  }
  .u-pl30--laptop {
    padding-left: 30px !important;
  }
  .u-pb30--laptop {
    padding-bottom: 30px !important;
  }
  .u-mt35--laptop {
    margin-top: 35px !important;
  }
  .u-mr35--laptop {
    margin-right: 35px !important;
  }
  .u-ml35--laptop {
    margin-left: 35px !important;
  }
  .u-mb35--laptop {
    margin-bottom: 35px !important;
  }
  .u-pt35--laptop {
    padding-top: 35px !important;
  }
  .u-pr35--laptop {
    padding-right: 35px !important;
  }
  .u-pl35--laptop {
    padding-left: 35px !important;
  }
  .u-pb35--laptop {
    padding-bottom: 35px !important;
  }
  .u-mt40--laptop {
    margin-top: 40px !important;
  }
  .u-mr40--laptop {
    margin-right: 40px !important;
  }
  .u-ml40--laptop {
    margin-left: 40px !important;
  }
  .u-mb40--laptop {
    margin-bottom: 40px !important;
  }
  .u-pt40--laptop {
    padding-top: 40px !important;
  }
  .u-pr40--laptop {
    padding-right: 40px !important;
  }
  .u-pl40--laptop {
    padding-left: 40px !important;
  }
  .u-pb40--laptop {
    padding-bottom: 40px !important;
  }
  .u-mt45--laptop {
    margin-top: 45px !important;
  }
  .u-mr45--laptop {
    margin-right: 45px !important;
  }
  .u-ml45--laptop {
    margin-left: 45px !important;
  }
  .u-mb45--laptop {
    margin-bottom: 45px !important;
  }
  .u-pt45--laptop {
    padding-top: 45px !important;
  }
  .u-pr45--laptop {
    padding-right: 45px !important;
  }
  .u-pl45--laptop {
    padding-left: 45px !important;
  }
  .u-pb45--laptop {
    padding-bottom: 45px !important;
  }
  .u-mt50--laptop {
    margin-top: 50px !important;
  }
  .u-mr50--laptop {
    margin-right: 50px !important;
  }
  .u-ml50--laptop {
    margin-left: 50px !important;
  }
  .u-mb50--laptop {
    margin-bottom: 50px !important;
  }
  .u-pt50--laptop {
    padding-top: 50px !important;
  }
  .u-pr50--laptop {
    padding-right: 50px !important;
  }
  .u-pl50--laptop {
    padding-left: 50px !important;
  }
  .u-pb50--laptop {
    padding-bottom: 50px !important;
  }
  .u-mt55--laptop {
    margin-top: 55px !important;
  }
  .u-mr55--laptop {
    margin-right: 55px !important;
  }
  .u-ml55--laptop {
    margin-left: 55px !important;
  }
  .u-mb55--laptop {
    margin-bottom: 55px !important;
  }
  .u-pt55--laptop {
    padding-top: 55px !important;
  }
  .u-pr55--laptop {
    padding-right: 55px !important;
  }
  .u-pl55--laptop {
    padding-left: 55px !important;
  }
  .u-pb55--laptop {
    padding-bottom: 55px !important;
  }
  .u-mt60--laptop {
    margin-top: 60px !important;
  }
  .u-mr60--laptop {
    margin-right: 60px !important;
  }
  .u-ml60--laptop {
    margin-left: 60px !important;
  }
  .u-mb60--laptop {
    margin-bottom: 60px !important;
  }
  .u-pt60--laptop {
    padding-top: 60px !important;
  }
  .u-pr60--laptop {
    padding-right: 60px !important;
  }
  .u-pl60--laptop {
    padding-left: 60px !important;
  }
  .u-pb60--laptop {
    padding-bottom: 60px !important;
  }
  .u-mt65--laptop {
    margin-top: 65px !important;
  }
  .u-mr65--laptop {
    margin-right: 65px !important;
  }
  .u-ml65--laptop {
    margin-left: 65px !important;
  }
  .u-mb65--laptop {
    margin-bottom: 65px !important;
  }
  .u-pt65--laptop {
    padding-top: 65px !important;
  }
  .u-pr65--laptop {
    padding-right: 65px !important;
  }
  .u-pl65--laptop {
    padding-left: 65px !important;
  }
  .u-pb65--laptop {
    padding-bottom: 65px !important;
  }
  .u-mt70--laptop {
    margin-top: 70px !important;
  }
  .u-mr70--laptop {
    margin-right: 70px !important;
  }
  .u-ml70--laptop {
    margin-left: 70px !important;
  }
  .u-mb70--laptop {
    margin-bottom: 70px !important;
  }
  .u-pt70--laptop {
    padding-top: 70px !important;
  }
  .u-pr70--laptop {
    padding-right: 70px !important;
  }
  .u-pl70--laptop {
    padding-left: 70px !important;
  }
  .u-pb70--laptop {
    padding-bottom: 70px !important;
  }
  .u-mt75--laptop {
    margin-top: 75px !important;
  }
  .u-mr75--laptop {
    margin-right: 75px !important;
  }
  .u-ml75--laptop {
    margin-left: 75px !important;
  }
  .u-mb75--laptop {
    margin-bottom: 75px !important;
  }
  .u-pt75--laptop {
    padding-top: 75px !important;
  }
  .u-pr75--laptop {
    padding-right: 75px !important;
  }
  .u-pl75--laptop {
    padding-left: 75px !important;
  }
  .u-pb75--laptop {
    padding-bottom: 75px !important;
  }
  .u-mt80--laptop {
    margin-top: 80px !important;
  }
  .u-mr80--laptop {
    margin-right: 80px !important;
  }
  .u-ml80--laptop {
    margin-left: 80px !important;
  }
  .u-mb80--laptop {
    margin-bottom: 80px !important;
  }
  .u-pt80--laptop {
    padding-top: 80px !important;
  }
  .u-pr80--laptop {
    padding-right: 80px !important;
  }
  .u-pl80--laptop {
    padding-left: 80px !important;
  }
  .u-pb80--laptop {
    padding-bottom: 80px !important;
  }
  .u-mt85--laptop {
    margin-top: 85px !important;
  }
  .u-mr85--laptop {
    margin-right: 85px !important;
  }
  .u-ml85--laptop {
    margin-left: 85px !important;
  }
  .u-mb85--laptop {
    margin-bottom: 85px !important;
  }
  .u-pt85--laptop {
    padding-top: 85px !important;
  }
  .u-pr85--laptop {
    padding-right: 85px !important;
  }
  .u-pl85--laptop {
    padding-left: 85px !important;
  }
  .u-pb85--laptop {
    padding-bottom: 85px !important;
  }
  .u-mt90--laptop {
    margin-top: 90px !important;
  }
  .u-mr90--laptop {
    margin-right: 90px !important;
  }
  .u-ml90--laptop {
    margin-left: 90px !important;
  }
  .u-mb90--laptop {
    margin-bottom: 90px !important;
  }
  .u-pt90--laptop {
    padding-top: 90px !important;
  }
  .u-pr90--laptop {
    padding-right: 90px !important;
  }
  .u-pl90--laptop {
    padding-left: 90px !important;
  }
  .u-pb90--laptop {
    padding-bottom: 90px !important;
  }
  .u-mt95--laptop {
    margin-top: 95px !important;
  }
  .u-mr95--laptop {
    margin-right: 95px !important;
  }
  .u-ml95--laptop {
    margin-left: 95px !important;
  }
  .u-mb95--laptop {
    margin-bottom: 95px !important;
  }
  .u-pt95--laptop {
    padding-top: 95px !important;
  }
  .u-pr95--laptop {
    padding-right: 95px !important;
  }
  .u-pl95--laptop {
    padding-left: 95px !important;
  }
  .u-pb95--laptop {
    padding-bottom: 95px !important;
  }
  .u-mt100--laptop {
    margin-top: 100px !important;
  }
  .u-mr100--laptop {
    margin-right: 100px !important;
  }
  .u-ml100--laptop {
    margin-left: 100px !important;
  }
  .u-mb100--laptop {
    margin-bottom: 100px !important;
  }
  .u-pt100--laptop {
    padding-top: 100px !important;
  }
  .u-pr100--laptop {
    padding-right: 100px !important;
  }
  .u-pl100--laptop {
    padding-left: 100px !important;
  }
  .u-pb100--laptop {
    padding-bottom: 100px !important;
  }
}

@media screen and (min-width: 768px) {
  .u-mt0--pc {
    margin-top: 0px !important;
  }
  .u-mr0--pc {
    margin-right: 0px !important;
  }
  .u-ml0--pc {
    margin-left: 0px !important;
  }
  .u-mb0--pc {
    margin-bottom: 0px !important;
  }
  .u-pt0--pc {
    padding-top: 0px !important;
  }
  .u-pr0--pc {
    padding-right: 0px !important;
  }
  .u-pl0--pc {
    padding-left: 0px !important;
  }
  .u-pb0--pc {
    padding-bottom: 0px !important;
  }
  .u-mt5--pc {
    margin-top: 5px !important;
  }
  .u-mr5--pc {
    margin-right: 5px !important;
  }
  .u-ml5--pc {
    margin-left: 5px !important;
  }
  .u-mb5--pc {
    margin-bottom: 5px !important;
  }
  .u-pt5--pc {
    padding-top: 5px !important;
  }
  .u-pr5--pc {
    padding-right: 5px !important;
  }
  .u-pl5--pc {
    padding-left: 5px !important;
  }
  .u-pb5--pc {
    padding-bottom: 5px !important;
  }
  .u-mt10--pc {
    margin-top: 10px !important;
  }
  .u-mr10--pc {
    margin-right: 10px !important;
  }
  .u-ml10--pc {
    margin-left: 10px !important;
  }
  .u-mb10--pc {
    margin-bottom: 10px !important;
  }
  .u-pt10--pc {
    padding-top: 10px !important;
  }
  .u-pr10--pc {
    padding-right: 10px !important;
  }
  .u-pl10--pc {
    padding-left: 10px !important;
  }
  .u-pb10--pc {
    padding-bottom: 10px !important;
  }
  .u-mt15--pc {
    margin-top: 15px !important;
  }
  .u-mr15--pc {
    margin-right: 15px !important;
  }
  .u-ml15--pc {
    margin-left: 15px !important;
  }
  .u-mb15--pc {
    margin-bottom: 15px !important;
  }
  .u-pt15--pc {
    padding-top: 15px !important;
  }
  .u-pr15--pc {
    padding-right: 15px !important;
  }
  .u-pl15--pc {
    padding-left: 15px !important;
  }
  .u-pb15--pc {
    padding-bottom: 15px !important;
  }
  .u-mt20--pc {
    margin-top: 20px !important;
  }
  .u-mr20--pc {
    margin-right: 20px !important;
  }
  .u-ml20--pc {
    margin-left: 20px !important;
  }
  .u-mb20--pc {
    margin-bottom: 20px !important;
  }
  .u-pt20--pc {
    padding-top: 20px !important;
  }
  .u-pr20--pc {
    padding-right: 20px !important;
  }
  .u-pl20--pc {
    padding-left: 20px !important;
  }
  .u-pb20--pc {
    padding-bottom: 20px !important;
  }
  .u-mt25--pc {
    margin-top: 25px !important;
  }
  .u-mr25--pc {
    margin-right: 25px !important;
  }
  .u-ml25--pc {
    margin-left: 25px !important;
  }
  .u-mb25--pc {
    margin-bottom: 25px !important;
  }
  .u-pt25--pc {
    padding-top: 25px !important;
  }
  .u-pr25--pc {
    padding-right: 25px !important;
  }
  .u-pl25--pc {
    padding-left: 25px !important;
  }
  .u-pb25--pc {
    padding-bottom: 25px !important;
  }
  .u-mt30--pc {
    margin-top: 30px !important;
  }
  .u-mr30--pc {
    margin-right: 30px !important;
  }
  .u-ml30--pc {
    margin-left: 30px !important;
  }
  .u-mb30--pc {
    margin-bottom: 30px !important;
  }
  .u-pt30--pc {
    padding-top: 30px !important;
  }
  .u-pr30--pc {
    padding-right: 30px !important;
  }
  .u-pl30--pc {
    padding-left: 30px !important;
  }
  .u-pb30--pc {
    padding-bottom: 30px !important;
  }
  .u-mt35--pc {
    margin-top: 35px !important;
  }
  .u-mr35--pc {
    margin-right: 35px !important;
  }
  .u-ml35--pc {
    margin-left: 35px !important;
  }
  .u-mb35--pc {
    margin-bottom: 35px !important;
  }
  .u-pt35--pc {
    padding-top: 35px !important;
  }
  .u-pr35--pc {
    padding-right: 35px !important;
  }
  .u-pl35--pc {
    padding-left: 35px !important;
  }
  .u-pb35--pc {
    padding-bottom: 35px !important;
  }
  .u-mt40--pc {
    margin-top: 40px !important;
  }
  .u-mr40--pc {
    margin-right: 40px !important;
  }
  .u-ml40--pc {
    margin-left: 40px !important;
  }
  .u-mb40--pc {
    margin-bottom: 40px !important;
  }
  .u-pt40--pc {
    padding-top: 40px !important;
  }
  .u-pr40--pc {
    padding-right: 40px !important;
  }
  .u-pl40--pc {
    padding-left: 40px !important;
  }
  .u-pb40--pc {
    padding-bottom: 40px !important;
  }
  .u-mt45--pc {
    margin-top: 45px !important;
  }
  .u-mr45--pc {
    margin-right: 45px !important;
  }
  .u-ml45--pc {
    margin-left: 45px !important;
  }
  .u-mb45--pc {
    margin-bottom: 45px !important;
  }
  .u-pt45--pc {
    padding-top: 45px !important;
  }
  .u-pr45--pc {
    padding-right: 45px !important;
  }
  .u-pl45--pc {
    padding-left: 45px !important;
  }
  .u-pb45--pc {
    padding-bottom: 45px !important;
  }
  .u-mt50--pc {
    margin-top: 50px !important;
  }
  .u-mr50--pc {
    margin-right: 50px !important;
  }
  .u-ml50--pc {
    margin-left: 50px !important;
  }
  .u-mb50--pc {
    margin-bottom: 50px !important;
  }
  .u-pt50--pc {
    padding-top: 50px !important;
  }
  .u-pr50--pc {
    padding-right: 50px !important;
  }
  .u-pl50--pc {
    padding-left: 50px !important;
  }
  .u-pb50--pc {
    padding-bottom: 50px !important;
  }
  .u-mt55--pc {
    margin-top: 55px !important;
  }
  .u-mr55--pc {
    margin-right: 55px !important;
  }
  .u-ml55--pc {
    margin-left: 55px !important;
  }
  .u-mb55--pc {
    margin-bottom: 55px !important;
  }
  .u-pt55--pc {
    padding-top: 55px !important;
  }
  .u-pr55--pc {
    padding-right: 55px !important;
  }
  .u-pl55--pc {
    padding-left: 55px !important;
  }
  .u-pb55--pc {
    padding-bottom: 55px !important;
  }
  .u-mt60--pc {
    margin-top: 60px !important;
  }
  .u-mr60--pc {
    margin-right: 60px !important;
  }
  .u-ml60--pc {
    margin-left: 60px !important;
  }
  .u-mb60--pc {
    margin-bottom: 60px !important;
  }
  .u-pt60--pc {
    padding-top: 60px !important;
  }
  .u-pr60--pc {
    padding-right: 60px !important;
  }
  .u-pl60--pc {
    padding-left: 60px !important;
  }
  .u-pb60--pc {
    padding-bottom: 60px !important;
  }
  .u-mt65--pc {
    margin-top: 65px !important;
  }
  .u-mr65--pc {
    margin-right: 65px !important;
  }
  .u-ml65--pc {
    margin-left: 65px !important;
  }
  .u-mb65--pc {
    margin-bottom: 65px !important;
  }
  .u-pt65--pc {
    padding-top: 65px !important;
  }
  .u-pr65--pc {
    padding-right: 65px !important;
  }
  .u-pl65--pc {
    padding-left: 65px !important;
  }
  .u-pb65--pc {
    padding-bottom: 65px !important;
  }
  .u-mt70--pc {
    margin-top: 70px !important;
  }
  .u-mr70--pc {
    margin-right: 70px !important;
  }
  .u-ml70--pc {
    margin-left: 70px !important;
  }
  .u-mb70--pc {
    margin-bottom: 70px !important;
  }
  .u-pt70--pc {
    padding-top: 70px !important;
  }
  .u-pr70--pc {
    padding-right: 70px !important;
  }
  .u-pl70--pc {
    padding-left: 70px !important;
  }
  .u-pb70--pc {
    padding-bottom: 70px !important;
  }
  .u-mt75--pc {
    margin-top: 75px !important;
  }
  .u-mr75--pc {
    margin-right: 75px !important;
  }
  .u-ml75--pc {
    margin-left: 75px !important;
  }
  .u-mb75--pc {
    margin-bottom: 75px !important;
  }
  .u-pt75--pc {
    padding-top: 75px !important;
  }
  .u-pr75--pc {
    padding-right: 75px !important;
  }
  .u-pl75--pc {
    padding-left: 75px !important;
  }
  .u-pb75--pc {
    padding-bottom: 75px !important;
  }
  .u-mt80--pc {
    margin-top: 80px !important;
  }
  .u-mr80--pc {
    margin-right: 80px !important;
  }
  .u-ml80--pc {
    margin-left: 80px !important;
  }
  .u-mb80--pc {
    margin-bottom: 80px !important;
  }
  .u-pt80--pc {
    padding-top: 80px !important;
  }
  .u-pr80--pc {
    padding-right: 80px !important;
  }
  .u-pl80--pc {
    padding-left: 80px !important;
  }
  .u-pb80--pc {
    padding-bottom: 80px !important;
  }
  .u-mt85--pc {
    margin-top: 85px !important;
  }
  .u-mr85--pc {
    margin-right: 85px !important;
  }
  .u-ml85--pc {
    margin-left: 85px !important;
  }
  .u-mb85--pc {
    margin-bottom: 85px !important;
  }
  .u-pt85--pc {
    padding-top: 85px !important;
  }
  .u-pr85--pc {
    padding-right: 85px !important;
  }
  .u-pl85--pc {
    padding-left: 85px !important;
  }
  .u-pb85--pc {
    padding-bottom: 85px !important;
  }
  .u-mt90--pc {
    margin-top: 90px !important;
  }
  .u-mr90--pc {
    margin-right: 90px !important;
  }
  .u-ml90--pc {
    margin-left: 90px !important;
  }
  .u-mb90--pc {
    margin-bottom: 90px !important;
  }
  .u-pt90--pc {
    padding-top: 90px !important;
  }
  .u-pr90--pc {
    padding-right: 90px !important;
  }
  .u-pl90--pc {
    padding-left: 90px !important;
  }
  .u-pb90--pc {
    padding-bottom: 90px !important;
  }
  .u-mt95--pc {
    margin-top: 95px !important;
  }
  .u-mr95--pc {
    margin-right: 95px !important;
  }
  .u-ml95--pc {
    margin-left: 95px !important;
  }
  .u-mb95--pc {
    margin-bottom: 95px !important;
  }
  .u-pt95--pc {
    padding-top: 95px !important;
  }
  .u-pr95--pc {
    padding-right: 95px !important;
  }
  .u-pl95--pc {
    padding-left: 95px !important;
  }
  .u-pb95--pc {
    padding-bottom: 95px !important;
  }
  .u-mt100--pc {
    margin-top: 100px !important;
  }
  .u-mr100--pc {
    margin-right: 100px !important;
  }
  .u-ml100--pc {
    margin-left: 100px !important;
  }
  .u-mb100--pc {
    margin-bottom: 100px !important;
  }
  .u-pt100--pc {
    padding-top: 100px !important;
  }
  .u-pr100--pc {
    padding-right: 100px !important;
  }
  .u-pl100--pc {
    padding-left: 100px !important;
  }
  .u-pb100--pc {
    padding-bottom: 100px !important;
  }
}

/* --------------------------
テキスト装飾
-------------------------- */
.u-text-inverse {
  color: #fff;
}

.u-text-important {
  color: #d34141;
}

.u-text-accent {
  color: #41aad3;
}

.u-text-thin {
  font-weight: 100;
}

.u-text-extraLight {
  font-weight: 200;
}

.u-text-light {
  font-weight: 300;
}

.u-text-regular {
  font-weight: 400;
}

.u-text-medium {
  font-weight: 500;
}

.u-text-semiBold {
  font-weight: 600;
}

.u-text-bold {
  font-weight: 700;
}

.u-text-extraBold {
  font-weight: 800;
}

.u-text-black {
  font-weight: 900;
}

.u-text-x-small {
  font-size: 0.75rem;
}

.u-text-small {
  font-size: 0.875rem;
}

.u-text-default {
  font-size: 1rem;
}

.u-text-large {
  font-size: 1.125rem;
}

.u-text-x-large {
  font-size: 1.25rem;
}

/* --------------------------
幅指定
-------------------------- */
.u-w0 {
  width: 0%;
}

.u-w10 {
  width: 10%;
}

.u-w20 {
  width: 20%;
}

.u-w30 {
  width: 30%;
}

.u-w40 {
  width: 40%;
}

.u-w50 {
  width: 50%;
}

.u-w60 {
  width: 60%;
}

.u-w70 {
  width: 70%;
}

.u-w80 {
  width: 80%;
}

.u-w90 {
  width: 90%;
}

.u-w100 {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .u-w0--pc {
    width: 0%;
  }
  .u-w10--pc {
    width: 10%;
  }
  .u-w20--pc {
    width: 20%;
  }
  .u-w30--pc {
    width: 30%;
  }
  .u-w40--pc {
    width: 40%;
  }
  .u-w50--pc {
    width: 50%;
  }
  .u-w60--pc {
    width: 60%;
  }
  .u-w70--pc {
    width: 70%;
  }
  .u-w80--pc {
    width: 80%;
  }
  .u-w90--pc {
    width: 90%;
  }
  .u-w100--pc {
    width: 100%;
  }
}

@media screen and (max-width: 1180px) {
  .u-w0--laptop {
    width: 0%;
  }
  .u-w10--laptop {
    width: 10%;
  }
  .u-w20--laptop {
    width: 20%;
  }
  .u-w30--laptop {
    width: 30%;
  }
  .u-w40--laptop {
    width: 40%;
  }
  .u-w50--laptop {
    width: 50%;
  }
  .u-w60--laptop {
    width: 60%;
  }
  .u-w70--laptop {
    width: 70%;
  }
  .u-w80--laptop {
    width: 80%;
  }
  .u-w90--laptop {
    width: 90%;
  }
  .u-w100--laptop {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .u-w0--tab {
    width: 0%;
  }
  .u-w10--tab {
    width: 10%;
  }
  .u-w20--tab {
    width: 20%;
  }
  .u-w30--tab {
    width: 30%;
  }
  .u-w40--tab {
    width: 40%;
  }
  .u-w50--tab {
    width: 50%;
  }
  .u-w60--tab {
    width: 60%;
  }
  .u-w70--tab {
    width: 70%;
  }
  .u-w80--tab {
    width: 80%;
  }
  .u-w90--tab {
    width: 90%;
  }
  .u-w100--tab {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .u-w0--sp {
    width: 0%;
  }
  .u-w10--sp {
    width: 10%;
  }
  .u-w20--sp {
    width: 20%;
  }
  .u-w30--sp {
    width: 30%;
  }
  .u-w40--sp {
    width: 40%;
  }
  .u-w50--sp {
    width: 50%;
  }
  .u-w60--sp {
    width: 60%;
  }
  .u-w70--sp {
    width: 70%;
  }
  .u-w80--sp {
    width: 80%;
  }
  .u-w90--sp {
    width: 90%;
  }
  .u-w100--sp {
    width: 100%;
  }
}

.u-h100 {
  height: 100%;
}

/* --------------------------
テキスト装飾
-------------------------- */
.u-line-basis {
  line-height: 1;
}

.u-line-tight {
  line-height: 1.25;
}

.u-line-default {
  line-height: 1.5;
}

.u-line-relaxed {
  line-height: 1.75;
}

.u-line-wide {
  line-height: 2;
}

.u-line-loose {
  line-height: 2.5;
}

@media screen and (max-width: 1024px) {
  .p-cvnav {
    padding: 16px 24px 24px 24px;
  }
}

.p-cvnav-list {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .p-cvnav-list {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.p-cvnav-list-item {
  height: 72px;
  width: 160px;
}

@media screen and (max-width: 1024px) {
  .p-cvnav-list-item {
    height: 64px;
    width: 100%;
  }
}

.p-cvnav-button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
}

@media screen and (max-width: 767px) {
  .p-cvnav-button {
    font-size: 1.25rem;
  }
}

.p-cvnav-button::before {
  font-family: "Material Icons";
  font-size: 1rem;
  margin-right: 8px;
}

@media screen and (max-width: 767px) {
  .p-cvnav-button::before {
    font-size: 1.25rem;
  }
}

.p-cvnav-button.contact {
  background: linear-gradient(135deg, #41aad3 0%, #98d1e7 100%);
  border-radius: 0px 0px 0px 24px;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .p-cvnav-button.contact {
    border-radius: 40px 40px 40px 0px;
  }
}

.p-cvnav-button.contact::before {
  content: "\e0e1";
}

.p-cvnav-button.contact:hover {
  color: #41aad3;
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

.p-cvnav-button.tel {
  color: #41aad3;
  background: linear-gradient(135deg, #dfeff6 0%, #fff 100%);
}

.p-cvnav-button.tel::before {
  content: "\e324";
}

.p-cvnav-button.tel:hover {
  color: #fff;
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

@media screen and (max-width: 1024px) {
  .p-cvnav-button.tel {
    border-radius: 40px 40px 40px 0px;
  }
}

/* --------------------------
Gナビ
-------------------------- */
.p-gnav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

@media screen and (max-width: 1024px) {
  .p-gnav {
    position: absolute;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 50%;
    height: 100vh;
    background: #fff;
    padding-top: 64px;
    display: block;
    transition: all 0.6s;
  }
  .p-gnav.active {
    right: 0;
  }
  .p-gnav-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    transition: all 0.6s;
    visibility: hidden;
  }
  .p-gnav-overlay.visible {
    background: rgba(0, 0, 0, 0.15);
    visibility: visible;
  }
}

@media screen and (max-width: 767px) {
  .p-gnav {
    width: 280px;
  }
}

.p-gnav-list {
  display: flex;
  gap: 8px;
}

@media screen and (max-width: 1024px) {
  .p-gnav-list {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 32px 24px 16px 24px;
  }
}

.p-gnav-list-item a {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #24292b;
  padding: 6px 8px 6px 8px;
  border-radius: 8px 8px 8px 0px;
}

.p-gnav-list-item a:hover {
  color: #fff;
  background: linear-gradient(107.74deg, #41aad3 0%, #98d1e7 100%);
}

/* --------------------------
ヘッダー
-------------------------- */
.p-header {
  height: 72px;
  width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding-left: 40px;
  z-index: 100;
}

@media screen and (max-width: 1024px) {
  .p-header {
    height: 64px;
    padding-left: 20px;
  }
}

.p-header-logo a {
  display: block;
}

.p-header-logo a img {
  width: 260px;
}

@media screen and (max-width: 1024px) {
  .p-header-logo a img {
    width: 216px;
  }
}

/* --------------------------
フッター
-------------------------- */
.p-footer {
  margin-top: 120px;
}

@media screen and (max-width: 767px) {
  .p-footer {
    margin-top: 70px;
  }
}

.p-footer-cta {
  padding: 100px 0;
  background: linear-gradient(135deg, #41aad3 0%, #98d1e7 100%);
  border-radius: 100px 100px 0px 0px;
}

@media screen and (max-width: 767px) {
  .p-footer-cta {
    padding: 70px 0;
    border-radius: 60px 60px 0px 0px;
  }
}

.p-footer-cta-title {
  color: #fff;
}

.p-footer-cta-title::before {
  background: linear-gradient(90deg, #dfeff6 0%, #fff 100%);
}

.p-footer-cta--small {
  color: #fff;
}

.p-footer-contents {
  padding: 32px 40px 20px 40px;
}

@media screen and (max-width: 767px) {
  .p-footer-contents {
    padding: 32px 20px 20px 20px;
  }
}

.p-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .p-footer-wrapper {
    flex-wrap: wrap;
  }
}

.p-footer-logo a {
  display: block;
}

.p-footer-logo a img {
  width: 260px;
}

.p-footer-sns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.p-footer-sns-item a {
  font-size: 1.5rem;
  color: #24292b;
  padding: 1px;
}

.p-footer-sns-item a:hover {
  color: #41aad3;
}

.p-footer-nav {
  margin-bottom: 40px;
}

.p-footer-nav-list {
  display: flex;
  align-items: center;
  margin: -4px;
}

@media screen and (max-width: 1024px) {
  .p-footer-nav-list {
    flex-wrap: wrap;
  }
}

.p-footer-nav-list-item {
  padding: 4px;
}

@media screen and (max-width: 1024px) {
  .p-footer-nav-list-item:not(:last-child) {
    display: none;
  }
}

.p-footer-nav-list-item a {
  display: block;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 8px 8px 8px 0px;
}

.p-footer-nav-list-item a:hover {
  color: #fff;
  background: linear-gradient(107.74deg, #41aad3 0%, #98d1e7 100%);
}

@media screen and (max-width: 1024px) {
  .p-footer-nav-list-item a {
    font-size: 0.875rem;
    padding: 8px;
  }
}

.p-footer-copyright {
  color: #333;
  font-size: 12px;
  font-weight: 400;
}

.p-mv {
  gap: 4px;
  height: 300px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(135deg, #dfeff6 0%, #98d1e7 52%);
  border-radius: 60px 0px 60px 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px 40px 24px 40px;
}

@media screen and (max-width: 767px) {
  .p-mv {
    height: 180px;
    margin: 0px 0 24px 0;
  }
}

.p-mv-title {
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-mv-title {
    font-size: 1.5rem;
  }
}

.p-mv-text {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.4;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-mv-text {
    font-size: 0.875rem;
  }
}

.p-image {
  border-radius: 40px 0px 40px 0px;
  overflow: hidden;
}

.p-image-thumbnail {
  border-radius: 40px 0px 0px 0px;
  overflow: hidden;
  position: relative;
  padding-top: calc((500 / 750) * 100%);
}

.p-image-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-grid {
  display: flex;
  gap: 24px;
}

@media screen and (max-width: 767px) {
  .p-grid {
    flex-wrap: wrap;
    gap: 16px;
  }
}

.p-grid-headline {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}

.p-grid-headline-number {
  height: 56px;
  width: 56px;
  color: #41aad3;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.4;
  text-align: center;
  background: #ffffff;
}

@media screen and (max-width: 767px) {
  .p-grid-headline-number {
    height: 48px;
    width: 48px;
    font-size: 20px;
  }
}

.p-grid-headline-icon {
  height: 64px;
  width: 64px;
  background: linear-gradient(135deg, #ecf7fc 0%, #fff 100%);
}

@media screen and (max-width: 767px) {
  .p-grid-headline-icon {
    height: 48px;
    width: 48px;
  }
}

.p-grid-headline-icon .icon {
  color: #41aad3;
  font-size: 36px;
}

@media screen and (max-width: 767px) {
  .p-grid-headline-icon .icon {
    font-size: 20px;
  }
}

.p-grid-contents {
  width: 100%;
}

.p-cta-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.p-cta-list--start {
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .p-cta-list {
    flex-wrap: wrap;
    gap: 16px;
  }
}

.p-cta-list-item {
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  .p-cta-list-item {
    width: 100%;
  }
}

.p-accordion-list-item {
  margin-bottom: 16px;
}

.p-accordion-list-item:last-child {
  margin-bottom: 0;
}

.p-accordion-wrapper {
  border-radius: 24px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .p-accordion-wrapper {
    border-radius: 16px;
  }
}

.p-accordion-header {
  padding: 24px;
}

@media screen and (max-width: 767px) {
  .p-accordion-header {
    padding: 16px;
  }
}

.p-accordion-header a {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  padding-right: 2em;
}

.p-accordion-header a.active:after {
  transform: rotate(0);
}

.p-accordion-header a::after {
  content: "\e316";
  font-family: "Material Icons";
  font-size: 1.75rem;
  color: #41aad3;
  position: absolute;
  right: 0;
  transform: rotate(180deg);
  transition: all 0.3s;
}

.p-accordion-icon {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
}

.p-accordion-icon.question {
  background: linear-gradient(100deg, #41aad3 0%, #98d1e7 100%);
  color: #fff;
}

.p-accordion-icon.answer {
  color: #41aad3;
  background: #fff;
  border: 2px solid #41aad3;
}

.p-accordion-question {
  font-weight: 500;
  position: relative;
}

.p-accordion-contents {
  display: none;
  padding: 24px;
  background: #ecf7fc;
}

@media screen and (max-width: 767px) {
  .p-accordion-contents {
    padding: 16px;
  }
}

.p-accordion-inner {
  display: flex;
  gap: 16px;
}

.top-keyvisual {
  position: relative;
  height: 615px;
  margin: 40px 40px 80px 40px;
  border-radius: 70px 0px 70px 0px;
  overflow: hidden;
  max-width: calc(100% - 80px);
}

@media screen and (max-width: 767px) {
  .top-keyvisual {
    height: 660px;
    border-radius: 60px 0px 60px 0px;
    margin: 8px 20px 40px 20px;
    max-width: calc(100% - 40px);
    background-position: left top;
  }
}

.top-keyvisual-slider {
  height: 100%;
}

.top-keyvisual-slider > .slick-list {
  height: 100%;
}

.top-keyvisual-slider > .slick-list > .slick-track {
  height: 100%;
}

.top-keyvisual-slider-image {
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .top-keyvisual-slider-image {
    background-position: center center;
  }
}

.top-keyvisual-slider-image01 {
  background-color: #a2a2a2;
}

.top-keyvisual-slider-image02 {
  background-color: #333;
}

.top-keyvisual-contents {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  padding: 48px;
}

@media screen and (max-width: 767px) {
  .top-keyvisual-contents {
    gap: 8px;
    padding: 16px 12px;
  }
}

.top-keyvisual-text {
  color: #41aad3;
  font-size: 18px;
  line-height: 1.4;
  padding: 8px 16px;
  background: #ffffff;
  border-radius: 12px 12px 12px 0px;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .top-keyvisual-text {
    padding: 6px 10px;
  }
}

.top-keyvisual-catchcopy {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0em;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .top-keyvisual-catchcopy {
    font-size: 6vw;
  }
}

.top-keyvisual-catchcopy span {
  display: inline-block;
  margin-bottom: 10px;
  padding: 8px 16px;
  background: linear-gradient(107.74deg, #41aad3 0%, #98d1e7 100%);
  border-radius: 16px 16px 16px 0px;
}

.top-keyvisual-catchcopy span:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .top-keyvisual-catchcopy span {
    padding: 6px 10px;
  }
}

.top-h2 {
  color: #41aad3;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
}

.top-h2-wrapper {
  padding-left: 16px;
  position: relative;
  margin-bottom: 24px;
}

.top-h2-wrapper::before {
  content: "";
  display: block;
  width: 6px;
  height: 100%;
  background: linear-gradient(180deg, #41aad3 0%, #98d1e7 100%);
  border-radius: 8px 8px 0px 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.top-h2-text {
  color: #24292b;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
}

.top-news-item:not(:last-child) {
  margin-bottom: 20px;
}

.top-news-item a {
  display: block;
}

.top-news-item a:hover .top-news-title {
  color: #41aad3;
}

.top-news-date {
  font-size: 12px;
  font-weight: 500;
  color: #a5a5a5;
  margin-bottom: 6px;
}

.top-news-title {
  color: #24292b;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 6px;
  transition: all 0.3s;
}

.top-news-text {
  color: #24292b;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
}

.top-schedule-table {
  width: 100%;
  border-collapse: collapse;
  color: #24292b;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .top-schedule-table {
    font-size: 12px;
  }
}

.top-schedule-table tr {
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.top-schedule-table tr td {
  padding: 16px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .top-schedule-table tr td {
    padding: 6px;
  }
}

.top-schedule-table tr th {
  line-height: 1.5;
  padding: 16px 16px 16px 0;
}

@media screen and (max-width: 767px) {
  .top-schedule-table tr th {
    padding: 12px 12px 12px 0;
  }
}

.top-service {
  flex-wrap: nowrap;
}

@media screen and (max-width: 767px) {
  .top-service {
    flex-wrap: wrap;
  }
}

.top-service-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background: linear-gradient(135deg, #ecf7fc 0%, #fff 100%);
  border-radius: 50%;
  margin-right: 25px;
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  .top-service-icon {
    margin-right: 0;
    margin-bottom: 16px;
    height: 48px;
    width: 48px;
  }
}

.top-service-icon i {
  color: #41aad3;
  font-size: 36px;
}

@media screen and (max-width: 767px) {
  .top-service-icon i {
    font-size: 20px;
  }
}

.top-service-contents {
  flex-shrink: 1;
}

.top-image {
  margin-right: 40px;
  border-top-right-radius: 100px;
  overflow: hidden;
}

.top-access {
  padding-top: 160px;
}

@media screen and (max-width: 767px) {
  .top-access {
    padding-top: 100px;
  }
}

.top-access-logo {
  width: 320px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .top-access-logo {
    width: 260px;
  }
}

.top-access-map {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.top-access-map iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.about-h1 {
  background-image: url(../images/about/h1.jpg);
}

.service-h1 {
  background-image: url(../images/service/h1.jpg);
  position: relative;
  z-index: -2;
  overflow: hidden;
}

.service-h1::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(2px);
  background: inherit;
  z-index: -1;
}

.service-image {
  border-radius: 24px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .service-image {
    border-radius: 16px;
  }
}

.service-panel {
  background: #f8f6f0;
  border-radius: 24px;
}

@media screen and (max-width: 767px) {
  .service-panel {
    border-radius: 16px;
  }
}

.service-panel--border {
  background: transparent;
  border: 2px dotted #41aad3;
}

.service-panel-contents {
  padding: 24px;
}

@media screen and (max-width: 767px) {
  .service-panel-contents {
    padding: 16px;
  }
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.service-list-item {
  width: 50%;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .service-list-item {
    width: 100%;
  }
}

.faq-h1 {
  background-image: url(../images/faq/h1.jpg);
}

.privacypolicy-h1 {
  background-image: url(../images/privacy/h1.jpg);
}

.contact-list-item {
  margin-bottom: 2em;
}

.contact-list-item:last-child {
  margin-bottom: 0;
}

.contact-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 1em;
}

.contact-link {
  padding-right: 0.5em;
  color: #41aad3;
  text-decoration: underline;
}

.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 64px;
  width: 50%;
  margin: 40px auto 0;
  font-weight: 500;
  line-height: 1.4;
  border-radius: 40px 40px 40px 0px;
  border: 2px solid #fff;
  background: linear-gradient(100deg, #41aad3 0%, #98d1e7 100%);
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .contact-button {
    width: 100%;
  }
}

.contact-button:hover {
  color: #41aad3;
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

input[type="radio"],
input[type="checkbox"] {
  appearance: none;
}

.wpcf7-form-control.wpcf7-radio {
  display: flex;
  gap: 2em;
}

@media screen and (max-width: 767px) {
  .wpcf7-form-control.wpcf7-radio {
    flex-wrap: wrap;
  }
}

.wpcf7-list-item {
  margin: 0 !important;
}

.wpcf7-list-item input[type="radio"]:checked + .wpcf7-list-item-label::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 8px solid #41aad3;
  border-radius: 50%;
}

.wpcf7-list-item input[type="checkbox"]:checked + .wpcf7-list-item-label::before {
  background: #41aad3;
}

.wpcf7-list-item input[type="checkbox"]:checked + .wpcf7-list-item-label::after {
  content: "";
  display: block;
  width: 0.5em;
  height: 1em;
  position: absolute;
  top: 0.2em;
  left: 0.5em;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.wpcf7 form .wpcf7-response-output {
  padding: 1em;
  text-align: center;
}

.news-section-container {
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;
}

.news-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

@media screen and (max-width: 767px) {
  .news-list {
    flex-wrap: wrap;
    gap: 16px;
  }
}

.news-list-item a {
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  padding-right: 2em;
  position: relative;
}

.news-list-item a:hover {
  color: #41aad3;
}

.news-list-item a:hover::before {
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

.news-list-item a:hover::before, .news-list-item a:hover::after {
  transform: scale(1.5, 1.5);
}

.news-list-item a::before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: linear-gradient(45deg, #41aad3 0%, #98d1e7 100%);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

.news-list-item a::after {
  content: "\e315";
  display: block;
  height: 20px;
  width: 20px;
  font-family: "Material Icons";
  font-size: 1rem;
  color: #fff;
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

.news-link {
  display: block;
}

.news-link .p-image-thumbnail img {
  transition: all 0.5s;
}

.news-link:hover .p-image-thumbnail img {
  transform: scale(1.15);
}

.news-label {
  display: inline-block;
  width: 8em;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-right: 16px;
}

.news-label.notice {
  background: linear-gradient(45deg, #41aad3 0, #98d1e7 100%);
}

.news-label.important {
  background: linear-gradient(45deg, #d34141 0, #e79898 100%);
}

.news-label.daily {
  background: linear-gradient(45deg, #42d442 0, #99e899 100%);
}

.news-label.news {
  background: linear-gradient(45deg, #d49742 0, #e8c799 100%);
}

.news-label.recruit {
  background: linear-gradient(45deg, #427ed4 0, #99bae8 100%);
}

.news-label.others {
  background: linear-gradient(45deg, #8c8c8c 0, #bfbfbf 100%);
}

.news-button {
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  padding: 0 2em;
  position: relative;
}

.news-button:hover {
  color: #41aad3;
}

.news-button:hover::before {
  background: linear-gradient(225deg, #dfeff6 0%, #98d1e7 100%);
}

.news-button:hover::before, .news-button:hover::after {
  transform: scale(1.5, 1.5);
}

.news-button--prev::before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: linear-gradient(45deg, #41aad3 0%, #98d1e7 100%);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

.news-button--prev::after {
  content: "\e315";
  display: block;
  height: 20px;
  width: 20px;
  font-family: "Material Icons";
  font-size: 1rem;
  color: #fff;
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

.news-button--next::before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: linear-gradient(45deg, #41aad3 0%, #98d1e7 100%);
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

.news-button--next::after {
  content: "\e314";
  display: block;
  height: 20px;
  width: 20px;
  font-family: "Material Icons";
  font-size: 1rem;
  color: #fff;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  transition: 0.3s cubic-bezier(0.4, 0.4, 0, 1);
}

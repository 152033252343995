/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  height: 18px;
  margin: 16px 0;
  background: rgba(0, 0, 0, 0);

  @include view-at(sp) {
    height: auto;
    margin: 12px 0;
  }

  &-container {
    padding: 0 40px;

    @include view-at(sp) {
      padding: 0 20px;
    }
  }

  &-list {
    display: flex;
    align-items: flex-start;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "＞";
        margin: 0 12px;
        color: #a5a5a5;
        font-size: 13px;
        font-weight: 400;

        line-height: 1.4;
      }

      &:last-child {
        &::after {
          content: none;
        }
      }

      a {
        color: #a5a5a5;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.4;
      }

      span {
        color: $color-blue;

        font-size: 12px;
        font-weight: 600;

        letter-spacing: 0.01em;
        line-height: 1.4;
      }
    }
  }
}

/* --------------------------
ボタン
-------------------------- */
.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 64px;
  width: 240px;
  font-weight: $medium;
  line-height: 1.4;
  border-radius: 40px 40px 40px 0px;

  @include view-at(sp) {
    width: 100%;
  }

  &::before {
    font-family: "Material Icons";
    font-size: rem(20px);
    margin-right: 16px;
  }

  &--blue {
    background: linear-gradient(
      100deg,
      rgb(65, 170, 211) 0%,
      rgb(152, 209, 231) 100%
    );
    border: none;
  }

  &--border {
    background: linear-gradient(135deg, #ecf7fc 0%, $color-white 100%);
    border: 2px solid $color-blue;
  }

  &--contact {
    font-size: rem(20px);
    color: $color-white;
    text-align: center;
    border: 2px solid $color-white;

    &:hover {
      color: $color-blue;
      background: linear-gradient(225deg, #dfeff6 0%, rgb(152, 209, 231) 100%);
    }

    &::before {
      content: "\e0e1";
    }
  }

  &--tel {
    font-size: 12px;
    background: linear-gradient(45deg, #ecf7fc 0%, $color-white 100%);
    color: $color-blue;

    &:hover {
      color: $color-white;
      background: linear-gradient(225deg, #dfeff6 0%, rgb(152, 209, 231) 100%);
    }

    &::before {
      content: "\e324";
    }
  }
}

.p-image {
  border-radius: 40px 0px 40px 0px;
  overflow: hidden;

  &-thumbnail {
    border-radius: 40px 0px 0px 0px;
    overflow: hidden;
    position: relative;
    padding-top: calc((500 / 750) * 100%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
